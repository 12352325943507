.profile_main_div {
  margin-top: 15%;
}
.profile-textarea textarea {
  width: 100% !important;
}
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
  /* border: 2px solid #e4e4e4 !important; */
}
.deleteacount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.personalinformationdiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pwdinputbox {
  margin: 1rem;
}
.namedivprofile {
  margin: 1rem;
  width: 50%;
}
.profile-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}
/* Main Container */
.profile-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
  background-color: hsla(0, 0%, 78%, 0.5058823529411764);
  padding-bottom: 5rem;
}
/* Main Right Container */
.profile-right-wrapper {
  background-color: white;
  border-radius: 10px;
  width: 70%;
  margin: auto;
  padding: 15px;
  margin-left: 18rem;
}

.profile-left-wrapper {
  position: relative;
}

.profile-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}

.profile-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #e6e5e3;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}

.profile-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.profile-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.profile-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}

.profile-sidebar-sub-menu-link {
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
}

.profile-btn-wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.profile-sidebar-right-wrapper {
  padding: 1em;
}

.profile-sidebar-personal-info-sec {
  /* border: 1px solid red; */
}

.profile-sidebar-personal-info-sec-header {
  /* border: 1px solid blue; */
}

.profile-sidebar-personal-info-sec-content,
.profile-sidebar-contact-info-sec-content {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.profile-sidebar-personal-info-sec-content-one-line {
  display: flex;
  justify-content: space-between;
  gap: 1em;
}

.profile-sidebar-personal-info-sec-content-span {
  display: flex;
  flex-direction: column;
  background-color: #e7e7e7;
  padding: 1em;
  border-radius: 9px;
}

.profile-sidebar-personal-info-sec-content-span-input,
.profile-contact-number-input-box {
  border: none;
  width: 100%;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  font-size: 1.5625rem;
  color: #363636;
  padding: 0.375em 0em;
}

.profile-sidebar-personal-info-sec-content-span-input:focus,
.profile-contact-number-input-box:focus {
  padding: 0.375em 1em;
  background-color: #afafaf;
  border-radius: 9px;
  transition: all 0.3s ease-in-out;
}

.profile-sidebar-contact-info-sec-ccphone-number-cc {
  font-size: 1.5625rem;
}

.profile-sidebar-personal-info-sec-content-span,
.profile-sidebar-personal-info-sec-content-span-address,
.profile-sidebar-contact-info-sec-ccphone-number,
.profile-sidebar-contact-info-sec-content-email,
.profile-sidebar-contact-info-sec-ccphone-number,
.profile-contact-code,
.profile-contact-number {
  display: flex;
  flex-direction: column;
  background-color: #e7e7e7;
  padding: 1em;
  border-radius: 9px;
}

.profile-sidebar-personal-info-sec-content-span > label,
.profile-sidebar-personal-info-sec-content-span-address > label,
.profile-sidebar-contact-info-sec-ccphone-number > label,
.profile-sidebar-contact-info-sec-content-email > label {
  margin-bottom: 0px !important;
  color: #878787;
}

.profile-sidebar-personal-info-sec-content-span > span,
.profile-sidebar-personal-info-sec-content-span-address > span,
.profile-sidebar-contact-info-sec-ccphone-number > span,
.profile-sidebar-contact-info-sec-content-email > span {
  font-size: 1.25rem;
  color: #363636;
}

.profile-sidebar-contact-info-sec-content-email > span {
  display: flex;
  align-items: center;
}

.profile-sidebar-contact-info-sec-content-email > span > label {
  margin-bottom: 0px !important;
  color: #878787;
  font-weight: normal;
  font-size: 1rem;
}

.profile-sidebar-personal-info-btns {
  display: flex;
  justify-content: center;
  gap: 1em;
}

.profile-sidebar-contact-info-sec-content {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.profile-sidebar-contact-info-sec-content-email {
  display: flex;
  flex-direction: column;
}

.profile-tag8-login-image {
  display: block;
  width: 50px;
}

.profile-sidebar-contact-info-sec-ccphone {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.profile-sidebar-contact-info-sec-ccphone-number {
  display: flex;
  flex-direction: column;
}

.profile-contact-info {
  /* border: 1px solid red; */
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.profile-contact-info > label {
  margin-bottom: 0px !important;
  text-align: center;
  font-size: 1.25rem;
}

.profile-contact-info-content {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.profile-contact-info-content-input {
  padding: 0.625em 1.875em;
  background-color: #e7e7e7 !important;
  border: none;
  border-radius: 9px;
  color: #363636;
  font-weight: bold;
  font-size: 1.25rem;
}

.profile-contact-info-content-checkbox {
  display: none;
}

.profile-change-pass-container {
  background-color: #e7e7e7;
  padding: 1em;
  display: flex;
  flex-direction: column;
  border-radius: 9px;
}

.profile-change-pass-container > label {
  margin-bottom: 0px !important;
  color: #878787;
}

.profile-contact-info-content-pass-input {
  width: 100%;
  padding: 0 0.375em;
  border: none;
  font-size: 1.5625rem;
  background-color: #e7e7e7;
  border-radius: 9px;
}

.profile-contact-info-content-pass-input:focus {
  padding: 0 0.375em;
}

.profile-change-password-btns-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.profile-change-password-btns {
  border: none;
  border-radius: 5px;
  padding: 1em 2em;
}

.profile-contact-info-content-center {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.profile-edit-btn {
  width: 50px;
  height: 50px;
  margin: 1em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  font-size: 1.3125em;
  color: #464646;
}

.profile-contact-phonesEdit {
  flex-direction: column;
  gap: 1em;
}

.profile-contact-phonesEdit > label {
  margin-bottom: 0px !important;
}

.select-contact-details {
  background-color: transparent;
  padding: 0.375em 1em;
  border-radius: 9px;
  border: none;
}

.profile-email-contact-edit {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.profile-contact-mobile-span,
.profile-contact-emergency-mobile-span {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.profile-contact-code-btns {
  justify-content: space-around;
}

.profile-contact-code-btns > button {
  border: none;
  padding: 1em 2em;
  border-radius: 9px;
}

.profile-change-pass-input-check-wrapper {
  display: flex;
  align-items: center;
}

.profile-change-pass-input-check-wrapper > label {
  margin-bottom: 0px !important;
}

/* ========== */

.fa-user-circle {
  font-size: 100px;
  color: white;
  margin-bottom: 5px;
}

.profile__button {
  text-decoration: none;
  align-self: baseline;
  margin-left: 0;
  font-size: 16px;
}
.profile__button:hover {
  text-decoration: none;
  color: rgb(236, 236, 236);
  /* background-color: #32383e; */
}
.profile__subButtons {
  /* color: white; */
  align-self: baseline;
  margin: 0 20px;
  font-size: 14px;
  padding: 10px;
}
.profile__subButtons:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
  /* background-color: #32383e; */
}
.profile__rigthPanel {
  flex: 0.75;
  margin: 30px 15px 0px 90px;
  /* background-color: white; */
}
.personal__firstLine {
  display: flex;
  margin-top: -30px !important;
}
#profile__personal {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 50px;
}
.profile__name {
  flex: 0.4;
  text-align: left;
  padding-left: 20px;
  padding-top: 40px;
}
.profile__editPersonal {
  color: #d3d3d3 !important;
  font-size: 30px;
  background-color: white;
  border: none;
  margin-left: auto;
  margin-right: 20px;
}
.personal__address {
  width: 100%;
  text-align: left;
  /* align-items: left; */
  margin-right: auto;
  margin-left: 20px;
  margin-top: 50px;
  margin-bottom: 30px;
}
.contact__header {
  /* margin: 20px; */
  /* padding-bottom: 10px; */
  display: flex;
  justify-content: space-around;
  /* border-bottom: 1px solid #ececec; */
  /* border: 1px solid red; */
}
.profile__editContact {
  color: #d3d3d3;
  font-size: 30px;
  background-color: white;
  border: none;
  margin-left: auto;
  margin-right: 20px;
  margin-top: -10px;
  width: 30px;
  height: 30px;
}
.profile__contact {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 50px;
}
.fa-envelope {
  color: #d3d3d3;
}
.contact__email {
  text-align: left;
  margin-left: 20px;
  margin-right: auto;
}
.input__field__dialogs {
  border: none;
  outline: none;
}
.contact__phones {
  display: flex;
  padding-bottom: 30px;
}

.contact__code {
  flex: 0.2;
  text-align: left;
  padding-left: 20px;
  padding-top: 40px;
}
.fa-envelope {
  font-size: 28px;
}
.editButtons {
  display: flex;
  flex-direction: row;
  padding: 20px;
  text-align: center;
  justify-content: space-around;
}

.saveButton {
  flex: 0.4;
  background-color: #ffbf00b2;
  margin: 5px 150px;
  margin-left: 0px;
  border: none;
  outline: none;
  border-radius: 10px;
  text-align: center;
  /* height: 34px; */
  justify-items: center;
  align-items: center;
  color: rgb(0, 0, 0);
  /* box-shadow: 0px 1px 5px 0px #888888; */
  width: 200px !important;
  font-size: 18px;
  font-weight: bold;
}
.saveButton:hover {
  background-color: #ffc107;
}

.cancelButton {
  flex: 0.4;
  background-color: rgb(194, 192, 192);
  border: none;
  outline: none;
  margin: 5px 150px;
  padding: 10px 15px;
  margin-left: 0px;
  border-radius: 5px;
  text-align: center;
  /* height: 34px; */
  justify-items: center;
  font-size: 18px;
  font-weight: bold;
  /* box-shadow: 0px 1px 5px 0px #888888; */
  width: 200px !important;
}
.cancelButton:hover {
  background-color: rgb(224, 222, 222);
}
.profile__name > input {
  margin-left: 0;
}
.personal__address > input {
  margin-left: 0;
  width: 90%;
}
.contact__phonesEdit {
  display: flex;
  flex-direction: column;
}
.contact__phonesEdit > div {
  display: flex;
}
.contact__phonesEdit > div > div {
  flex: 0.5;
}
.fa-mobile {
  color: #d3d3d3;
}
.profile__name > input {
  width: 75%;
  margin-top: 10px;
  background-color: white;
  border: 1px solid #dadfe3;
  padding: 5px;
  border-radius: 1px;
  font-weight: 400;
  font-size: 14px;
}
.personal__address > input {
  margin-top: 10px;
  background-color: white;
  border: 1px solid #dadfe3;
  padding: 5px;
  border-radius: 1px;
  font-weight: 400;
  font-size: 14px;
}
.contact__code > select {
  width: 50%;
  margin-top: 10px;
  background-color: white;
  border: 1px solid #dadfe3;
  padding: 5px;
  border-radius: 1px;
  font-weight: 400;
  font-size: 14px;
}
.contact__number {
  flex: 0.4;
  text-align: left;
  padding-left: 20px;
  padding-top: 40px;
}
.contact__number > input {
  width: 50%;
  margin-top: 10px;
  background-color: white;
  border: 1px solid #dadfe3;
  padding: 5px;
  border-radius: 1px;
  font-weight: 400;
  font-size: 14px;
}
.input-contact-details {
  border: none;
  outline: none;
  box-shadow: none;
  outline-width: 0;
}
#labels {
  font: 400 16px/28px Roboto, Helvetica Neue, sans-serif;
  font-weight: 550;
  /* font-size: 20px; */
}
#values {
  font: 400 14px/20px Roboto, Helvetica Neue, sans-serif;
  font-size: 18px;
  font-weight: 400 !important;
  letter-spacing: normal;
}

@media only screen and (max-width: 1180px) {
  .personal__address {
    width: 650px !important;
  }

  .personal__address h3,
  .personal__address input {
    width: 650px !important;
  }
  .profile__subButtons {
    margin-left: 10px !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 600px) {
  .profile_main_div {
    margin-top: 85px;
  }
}

@media only screen and (max-width: 1160px) {
  .personal__address input {
    width: 100% !important;
  }
  .profile__rigthPanel {
    width: 10rem;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 958px) {
  .profile__rigthPanel {
    margin-left: -25px !important;
    padding-left: 65px !important;
  }

  #profile__personal,
  #profile__contact {
    margin-left: 0px !important;
    width: 550px !important;
  }

  .addinput {
    width: 400px !important;
  }

  .personal__address {
    width: 400px !important;
    padding-right: 0px !important;
  }

  .personal__address h3,
  .personal__address input {
    width: 400px !important;
    margin-right: 0px !important;
  }

  .cancelButton,
  .saveButton {
    width: 200px !important;
  }

  .contact__phonesEdit input,
  .contact__phonesEdit select {
    width: 80% !important;
  }

  .contact__phonesEdit select {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 991px) {
  .inner_personal_fl {
    flex-direction: column !important;
  }
  .pro_name {
    margin-left: 0px !important;
  }
  .profile__editPersonal {
    margin-top: 0px !important;
  }
  .profile {
    flex-direction: column !important;
  }

  #profile__personal,
  #profile__contact {
    width: 100% !important;
  }

  .profile__rigthPanel {
    flex: 0.75;
    margin: 30px 40px;
  }

  .personal__address input {
    width: 400px !important;
  }
}

@media only screen and (max-width: 900px) {
  .personal__address input {
    width: 400px !important;
  }
}

@media only screen and (max-width: 830px) {
  .editButtons {
    flex-direction: column !important;
  }
  .saveButton,
  .cancelButton {
    padding: 6px !important;
    height: 55px !important;
  }
}

@media (max-width: 780px) {
  .profile {
    flex-direction: column !important;
  }

  #profile__personal,
  #profile__contact {
    width: 100% !important;
  }

  .profile__rigthPanel {
    flex: 0.75;
    margin: 30px 40px;
  }

  .personal__address input {
    width: 400px !important;
  }
}

@media only screen and (max-width: 768px) {
  .name_input {
    width: 400px !important;
  }
}

@media screen and (max-width: 768px) and (min-width: 280px) {
  /* mini inputs off */

  .profile__leftPanel h2 {
    font-size: 18px !important;
  }
  .profile__button {
    font-size: 15px !important;
  }

  .profile__subButtons,
  .profile__subButtons {
    font-size: 12px !important;
    margin-top: -8px !important;
  }

  .fa-user-circle {
    font-size: 70px !important;
  }

  .personal__firstLine button {
    font-size: 10px !important;
  }
}

@media (max-width: 640px) {
  .contact__phones {
    flex-direction: column;
  }

  .profile__personal {
    flex-direction: row !important;
  }
}

@media only screen and (max-width: 508px) {
  .personal__address {
    width: 250px !important;
  }

  .personal__address h3,
  .personal__address input {
    width: 250px !important;
  }

  .personal__firstLine input {
    width: 250px !important;
  }
}

@media (max-width: 500px) {
  .contact__phonesEdit {
    flex-direction: column;
  }
  .contact__phonesEdit > div {
    flex-direction: column;
  }
}

@media only screen and (max-width: 430px) {
  .edit-btn-div button {
    margin-left: -15px !important;
    margin-top: 10px !important;
  }

  .edit-btn-div {
    margin-left: -15px !important;
    margin-top: 10px !important;
  }

  #profile__personal,
  #profile__contact {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 400px) {
  #profile__personal,
  #profile__contact {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 95% !important;
  }

  .profile {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 380px) {
  .profile__rigthPanel {
    margin: 10px 10px 10px -55px !important;
  }
  .personal__address > input {
    width: 120% !important;
  }
  .profile__name > input {
    width: 130% !important;
  }
  .contact__number > input {
    width: 90% !important;
  }
  .contact__code > select {
    width: 90% !important;
  }
  /* .personal__address
      {
          width: 160px !important;
      }
      
      .personal__address h3, .personal__address input
      {
          width: 160px !important;
      }
  
      .personal__firstLine input
      {
          width: 160px !important;
      } */
}

@media only screen and (max-width: 355px) {
  .personal__address {
    width: 100% !important;
  }

  .personal__address h3,
  .personal__address input {
    width: 200px !important;
  }
}
@media screen and (max-width: 767px) {
  .namedivprofile {
    width: 90%;
  }
}

@media screen and (max-width: 314px) and (min-width: 260px) {
  .saveButton,
  .cancelButton {
    width: 160px !important;
  }
}

@media screen and (min-width: 48em) {
  .profile-sidebar-right-wrapper {
    padding: 1rem 6.875rem;
  }
}

@media screen and (min-width: 62.5em) {
  .profile-sidebar-right-wrapper {
    margin: 1.5em 5em 0em 15em;
  }

  .profile-sidebar-toggle-btn-wrapper {
    display: none;
  }

  .profile-sidebar-container-hide {
    position: fixed;
    width: 20%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }
}

@media screen and (min-width: 64em) {
  .profile-sidebar-right-wrapper {
    padding: 1rem;
  }
}

@media screen and (min-width: 75em) {
  .profile-sidebar-right-wrapper {
    margin: 1em 30%;
  }
}

@media screen and (min-width: 78.125em) {
  .profile-sidebar-menu-link {
    font-size: 1rem;
  }

  .profile-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}
@media (max-width: 966px) {
  .profile-right-wrapper {
    background-color: white;
    border-radius: 10px;
    width: 100%;
    margin: auto;
    padding: 30px;
  }
}
@media screen and (width: 64em) {
  .profile-right-wrapper {
    padding: 0 11% 0 23%;
  }
}
@media screen and (max-width: 360px) {
  .profile-main-wrapper {
    /* width: min-content; */
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .personalinformationdiv {
    display: block;
  }
  .deleteacount {
    display: block;
  }
}
