.update-card-details-form-wrapper {
  padding: 1em;
}

.update-card-details-form-wrapper > form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.update-card-details-form-wrapper > form > h4 {
  text-align: center;
}

.update-card-details-form-content {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.update-card-details-form-content > label {
  margin-bottom: 0px !important;
  color: gray;
}

.update-card-details-form-content textarea {
  width: 100% !important;
}

.update-card-details-form-content textarea,
.update-card-details-form-input {
  padding: 0.5em 1em;
  border: 1px solid black;
  border-radius: 15px;
  background-color: white;
}

.update-card-details-form-checkbox {
  display: flex;
  align-items: center;
  gap: 1em;
}

.update-card-details-form-checkbox > label {
  margin-bottom: 0px !important;
}

.update-card-details-uploadReport-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.update-card-details-uploadReport-container > label {
  margin-bottom: 0px !important;
  display: flex;
  gap: 0.375em;
}

.update-card-details-uploadReport-container > a {
  font-size: 2rem;
}

.update-card-details-btn-wrapper {
  margin: 1em 0;
  display: flex;
  justify-content: center;
  gap: 1em;
}

.update-card-details-btns {
  width: 100%;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 15px;
  box-shadow: 0 2px 5px 0 #888;
}