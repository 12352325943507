* {
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  /* font-weight: 400; */
  font-stretch: normal;
  line-height: 20px;
  font-family: "prompt";
}
.signup__background {
  background-image: url("https://storage.googleapis.com/pettag/qr/assets/grey.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.signup__container {
  background: linear-gradient(to bottom, #fffdfe, #f4ebec9c, #f1f1f18c);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6rem 1rem -2rem 1rem;
}
.Signupform-textfield {
  display: flex;
  flex-direction: row;
  gap: 1.7em;
  /* padding: 1rem 2rem !important; */
}

.Signupform-textfield-country {
  display: flex;
  flex-direction: row;
  gap: 1.7em;
  /* margin: 1rem 4rem 1rem 0rem; */
}
/* .selectclass-width {
  width: 230px;
} */
.signup__panel {
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: center;
  width: 72%;
  margin: auto !important;
  margin-top: 50px !important;
}
.signup__leftColumn {
  flex: 0.33;
  background-color: #343a40;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}
.signup__rightColumn {
  flex: 0.67;
  padding: 10px;
  text-align: left;
  background-color: white;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}
.text-center {
  text-align: center !important;
}
.signup__rightColumn > p {
  font: 400 14px/20px Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  letter-spacing: normal;
}
.signup__form {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1em;
  padding: 20px 0x !important;
  width: 100%;
}
.signup__form > h1 {
  font: 400 24px/32px Roboto, Helvetica Neue, sans-serif;
  font-weight: 550;
  padding: 15px;
  padding-bottom: 0;
}

.signup__form > input {
  /* justify-content: center; */
  font: 400 14px/20px Roboto, Helvetica Neue, sans-serif;
  font-size: 16px !important;
  padding: 10px;
  margin: 10px;
  width: 100%;
  color: #495057;
  background-color: #f3f3f3;
  border: none;
  border-radius: 5px;
  outline: none;
  font-weight: 400;
}
.signup__form > select {
  font: 400 14px/20px Roboto, Helvetica Neue, sans-serif;
  font-size: 16px !important;
  padding: 10px;
  margin: 10px;
  width: 100%;
  color: #495057;
  background-color: #f3f3f3;
  border: none;
  border-radius: 5px;
  outline: none;
  font-weight: 400;
}
::placeholder {
  font-size: 16px !important;
}
#signup__button {
  /* margin: 10px; */
  margin-top: 0px;
  font: 400 14px/20px Roboto, Helvetica Neue, sans-serif;
  font-size: 16px;
  background-color: #fcbc1b;
  color: white;
  /* width: 100%; */
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}
#signup__button:hover {
  cursor: pointer;
  background-color: #cf9b17;
}
#signup__button:disabled {
  cursor: inherit;
  background-color: #d3d3d3;
  color: white;
}

.signup__register {
  font-size: 16px;
  align-self: center;
  text-align: center;
  padding-bottom: 15px;
}
.signup__register > a {
  /* padding: 12px; */
  color: black;
  align-self: center;
}
.signup__register > a:hover {
  color: black;
  /* text-decoration: none; */
}
.fa-google {
  font-size: 18px;
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  margin-right: 12px;
}
.signup__google {
  padding-top: 0;
  margin-top: 10px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: left;
  width: 100%;
}
.google__button {
  font: 400 14px/20px Roboto, Helvetica Neue, sans-serif;
  font-size: 16px;
  background-color: white;
  color: balck;
  height: 40px;
  border: 2px solid black;
  outline: none;
  border-radius: 5px;
  font-weight: 400;
  width: 90%;
  margin-left: 20px;
}
.google__button:hover {
  cursor: pointer;
}
#terms {
  color: #007bff !important;
  font-weight: 600;
}
#terms:hover {
  color: #0056b3 !important;
  text-decoration: underline !important;
  font-weight: 600;
}
.AlertSignup {
  /* z-index: 200; */
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  box-shadow: 0px 0px 8px 0px #888888;
  color: #bebebe;
  background-color: #323232;
  padding: 10px 20px;
  width: 400px;
  /* height: 100px; */
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  align-self: center;
  border-radius: 5px;
  margin-top: 10px;
}
.AlertSignup > p {
  /* margin-top: 10px; */
  margin-bottom: 0;
}
.AlertSignup > button {
  margin: 0;
  padding: 0;
  color: #ff3b52;
  background-color: inherit;
  border: none;
  margin-left: auto;
}
.verifyOtp-button {
  padding: 5px 10px;
  background-color: #ffc107;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px 0px #888888;
  border-radius: 5px;
  font-size: 16px;
  color: black;
}
.otp-buttons {
  color: black;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: white;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px 0px #888888;
  font-size: 16px;
}
.verifyOtp-button:hover {
  background-color: #d39e00;
}
/* Media Queries */

@media only screen and (max-width: 1000px) {
  .signup__google {
    width: 60% !important;
  }
  .signup__form {
    width: 70% !important;
    margin-left: 60px !important;

    /* padding: auto 20px !important; */
  }

  .google__button {
    width: 130% !important;
  }
}

@media only screen and (max-width: 950px) {
  .signup__form {
    width: 75% !important;
    margin-left: 50px !important;
  }
}

@media only screen and (max-width: 768px) {
  .selectclass-width {
    width: 122px !important;
  }
  .mobileresponsive-css {
    width: 122px !important;
  }
}
@media only screen and (max-width: 800px) {
  .signup__form {
    width: 80%;
    margin-left: 35px !important;
  }
  .google__button {
    width: 85%;
  }
  /* .selectclass-width {
    width: 100% !important;
  } */
  .Signupform-textfield-country {
    flex-direction: row;
    margin: 0;
  }
  /* .selectclass-width {
    width: 122px !important;
  } */
  .mobileresponsive-css {
    width: 133px;
  }
}

@media only screen and (max-width: 650px) {
  .signup__leftColumn {
    display: none;
  }
  .signup__rightColumn {
    flex: 1;
    border-radius: 15px;
  }

  .signup__form {
    margin-left: 35px !important;
  }

  .signup__form {
    margin-top: 3px !important;
  }

  .google__button {
    width: 95% !important;
    margin-left: 0px !important;
  }
  .selectclass-width {
    width: 250px;
  }
}

@media only screen and (max-width: 820px) {
  .selectclass-width {
    width: 130px !important;
  }
  .mobileresponsive-css {
    width: 130px !important;
  }
}
@media only screen and (max-width: 480px) {
  .signup__panel {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 90% !important;
    padding-left: 0px !important;
  }

  .google__button {
    width: 130% !important;
    margin-left: 25px !important;
  }

  .login__panelWrapper {
    padding: 0px !important;
  }

  .signup__form {
    width: 83% !important;
    padding-left: 0px !important;
    margin-left: 25px !important;
  }
  .Signupform-textfield {
    flex-direction: column;
  }
  .Signupform-textfield-country {
    flex-direction: column;
    margin: 0;
  }
  .selectclass-width {
    width: 100% !important;
  }
  .mobileresponsive-css {
    width: 100% !important;
  }
}

@media only screen and (max-width: 362px) {
  .signup__form {
    margin-left: 30px !important;
  }
  .signup__google {
    margin-left: 55px !important;
  }
  .signup__form > input {
    font-size: 14px !important;
    width: 110% !important;
  }
  .signup__form > select {
    font-size: 14px !important;
    width: 110% !important;
  }
  ::placeholder {
    font-size: 14px !important;
  }
  .signup {
    padding: 2rem 0rem 4rem 0rem !important;
    height: 125vh;
  }
  .Signupform-textfield {
    flex-direction: column;
  }
  .Signupform-textfield-country {
    flex-direction: column;
    margin: 0;
  }
}

@media only screen and (max-width: 345px) {
  .verify-mail {
    width: 300px !important;
  }
}

@media only screen and (max-width: 360px) {
  .selectclass-width {
    width: 100%;
  }
}

@media only screen and (max-width: 305px) {
  .verify-mail {
    width: 270px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .forget_password_div button {
    width: 205px !important ;
    margin: 10px 25px !important;
  }
}

@media only screen and (max-width: 900px) {
  .verify-mail input {
    width: 300px !important;
    margin-top: 10px !important;
    margin: 10px auto !important;
  }

  .verify-mail button {
    width: 300px !important;
    margin-top: 5px !important;
    margin: 5px auto !important;
  }

  .verify-mail p,
  .verify-mail h1 {
    margin: auto !important;
    margin-bottom: 10px !important;
    text-align: center !important;
  }
}
@media only screen and (max-width: 600px) {
  .verify-mail {
    padding-top: 30px !important;
  }
  .verify-mail input {
    width: 280px !important;
  }

  .verify-mail button {
    width: 280px !important;
  }
}

@media only screen and (max-width: 415px) {
  .verify-mail input {
    width: 200px !important;
  }
  .verify-mail button {
    width: 200px !important;
  }
}

@media only screen and (max-width: 355px) {
  .verify-mail input {
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 200px !important;
  }
}

@media only screen and (max-width: 350px) {
  .verify-mail {
    width: fit-content !important;
  }

  .verify-mail input {
    width: 220px !important;
    margin: 5px 0px !important;
  }

  .verify-mail button {
    width: 220px !important;
    margin: 5px 0px !important;
  }
}

@media only screen and (max-width: 347px) {
  .verifyOtp-button,
  .otp-buttons {
    width: 80px !important;
    padding: 5px !important;
    margin-left: 55px !important;
  }
}

@media screen and (max-width: 350px) {
  .forget_password_div {
    width: 250px !important;
  }
  .forget_password_div input {
    width: 180px !important;
    margin: 15px auto !important;
  }

  .forget_password_div button {
    width: 180px !important;
    margin: 10px auto !important;
  }
}

@media screen and (max-width: 330px) {
  .forget_password_div input {
    width: 180px !important;
    margin: 10px auto !important;
  }

  .forget_password_div button {
    width: 180px !important;
    margin: 5px auto !important;
  }

  .forget_password_div {
    width: 230px !important;
    padding: 5px !important;
  }
}

@media only screen and (max-width: 328px) {
  .verify-mail input {
    width: 200px !important;
    margin: 5px 0px !important;
  }

  .verify-mail button {
    width: 200px !important;
    margin: 5px 0px !important;
  }
}

@media only screen and (max-width: 300px) {
  .verify-mail input {
    width: 150px !important;
    margin-left: 15px !important;
    margin-right: 2px !important;
  }

  .verify-mail button {
    width: 150px !important;
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
}

@media only screen and (max-width: 375px) {
  .signup {
    padding: 20rem 0rem 18rem 0rem;
    height: 135vh;
  }
  .selectclass-width {
    width: 100%;
  }
}

@media only screen and (max-width: 390px) {
  .selectclass-width {
    width: 100%;
  }
}
@media screen and (min-width: 770px) {
  .signup__container {
    margin: 10rem 1rem -2rem 1rem !important;
  }
}
/* @media screen and (min-width: 48em) {
  .Signupform-textfield {
    flex-direction: column;
    justify-content: space-between;
  }
} */
