.calendar_container_det{
    text-align: center;
    width: 100%;
    padding-top: 1rem;
    border-top: 1px solid rgb(211, 211, 211);
}
.Med_tit{
    font-size: 1.3rem;
    margin-bottom: 1rem;
    padding: 10px;
    border: 1px solid gray;
    display: inline-block;
    border-radius: 5px;
    box-shadow: 2px 2px 7px gray;
}
.inside_detail_con{
    display: flex ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.summary_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgb(189, 187, 187);
    padding: 15px 15px;
    margin-bottom: 20px;
}

.table1{
    margin-left: 30px !important;
}

.table1 td{
    text-align: center;
}
.table1 th{
    text-align: center;
}