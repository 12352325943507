.app {
  position: relative;
}
.app.sidebar-open .content {
  filter: blur(5px);
}
.toggle-button,
.mobile-toggle-button {
  position: absolute;
  left: 12px;
  top: 12px;
  z-index: 1050;
  background: none;
  border: none;
  font-size: 24px;
  background-color: #ffffff4d;
  border: 0.75px solid #000 !important;
  border-radius: 4px;
  padding: 4px;
  /* transition: left 0.3s ease; */
}
.sidebar-icons {
  width: 1.75rem;
}
.sidebar-sub-icons {
  width: 1rem;
}
.toggle-button:focus,
.mobile-toggle-button:focus {
  outline: none !important;
  border: 0.75px solid #000 !important;
}

.sidebar {
  position: fixed;
  top: 170px;
  left: 0;
  width: 60px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  /* transition: width 0.3s ease; */
  z-index: 1040;
}
.app.sidebar-collapsed .toggle-button {
  left: 175px;
}
.sidebar.collapsed {
  width: 220px;
}

.sidebar-menu {
  padding: 60px 0 0 0;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 18px 16px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.menu-item span {
  display: none;
  margin-left: 10px;
}
.menu-item svg {
  width: 20px;
  height: 30px;
}
.sidebar.collapsed .menu-item span {
  display: inline;
}

.submenu {
  padding-left: 20px;
}

.submenu .menu-item {
  padding: 10px 0;
}

.sidebar-logo {
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.shop-now-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding: 26rem 3rem 0rem 3rem;
}

.shop-now-button {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s, color 0.3s;
}

.shop-now-button:hover {
  background-color: black;
  color: white;
}

@media (max-width: 768px) {
  .toggle-button {
    display: none;
  }

  .mobile-toggle-button {
    left: 20px;
    top: 80px;
    padding: 0px;
  }
  .app.sidebar-collapsed .mobile-toggle-button {
    left: 200px;
    top: 80px;
  }
  .sidebar {
    top: 93px !important;
    width: 0;
    height: 100%;
    /* transition: width 0.3s ease; */
  }

  .sidebar.collapsed {
    width: 230px;
  }

  .app.sidebar-collapsed .sidebar {
    /* top: 0; */
    left: 0;
  }
}

@media (min-width: 361px) and (max-width: 768px) {
  .sidebar {
    top: 112px !important;
  }

  .app.sidebar-collapsed .sidebar {
    top: 92px !important;
  }
}
