* {
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-stretch: normal;
  /* font-family: Roboto, "Helvetica Neue", sans-serif; */
  font-family: "prompt", sans-serif;
}
