
.loginAnimation{
    position: relative;
    width: 100%;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.context{
    width: 100%;
    position: absolute;
    top: 50vh;
}
.area {
    /* position: relative; */
    background: #4e54c8;
    background: -webkit-linear-gradient(270deg,#8f94fb,#4e54c8);
    width: 100%;
    color: #212529;
}
.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
}
.circles> li:first-child {
    left: 25%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
.circles> li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: 12s;
    animation-duration: 12s;
}
.circles> li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}
.circles> li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-duration: 18s;
    animation-duration: 18s;
}
.circles> li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
.circles >li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}
.circles> li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
}
.circles> li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    -webkit-animation-delay: 15s;
    animation-delay: 15s;
    -webkit-animation-duration: 45s;
    animation-duration: 45s;
}
.circles> li:nth-child(9) {

    left: 20%;
    width: 15px;
    height: 15px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: 35s;
    animation-duration: 35s;

}
.circles> li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-duration: 11s;
    animation-duration: 11s;
}
.circles > li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: hsla(0,0%,100%,.2);
    -webkit-animation: animate 25s linear infinite;
    animation: animate 25s linear infinite;
        animation-delay: 0s;
    bottom: -150px;
}
@keyframes animate{
0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
}
100% {
    transform: translateY(-1000px) rotate(2turn);
    opacity: 0;
    border-radius: 50%;
}
}