.keyicons {
  margin-top: -10px;
  margin-left: -80px;
}

@media only screen and (max-width: 650px) {
  .keycardtext {
    margin-top: 155px !important;
    margin-left: -130px !important;
  }
  .keytagdetailsandicons {
    margin-right: 0px !important;
    margin-top: 0px !important;
  }
  /* .keyicons {
    margin-top: 100px !important;
  } */
}

@media only screen and (max-width: 500px) {
  .keytagdetailsandicons {
    margin-left: 150px !important;
    margin-top: -200px !important;
  }
  .keyicons {
    width: 300px !important;
    margin-bottom: 30px !important;
    margin-top: 150px !important;
    margin-left: -220px !important;
  }
}

@media only screen and (max-width: 350px) {
  .keyicons {
    width: 300px !important;
    margin-bottom: 30px !important;
    margin-top: 150px !important;
    margin-left: -235px !important;
  }
  .keytagdetails {
    width: 150px !important;
    margin-top: -20px !important;
    margin-left: -70px !important;
    text-align: left !important;
  }
}
