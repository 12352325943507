#EventLog b {
  color: #388e3c;
}

hr {
  margin: 1px 10px 1px 0px;
  border-top: 1px solid #eee;
}
/* custom code end*/

#_dialog_wrapper {
  width: 590px !important;
}

.e-appointment {
  border-radius: 3px !important;
  margin-left: -13px !important;
  margin-top: 10px !important;
  height: 25px !important;
  border: 1px solid black !important;
  box-shadow: 1px 1px 5px gray;
  background-color: rgb(219, 92, 69) !important;
}

.e-appointment-details {
  cursor: pointer;
  border-radius: 3px;
  padding-left: 1px;
}

.e-appointment-details:hover {
  background-color: #d1f140;
  color: black;
  transition: all 0.2s;
}

.e-schedule .e-month-view .e-content-table {
  height: 90% !important;
}

.e-schedule .e-schedule-toolbar .e-icon-schedule-print::before {
  content: "\e973";
  margin-left: -30px !important;
}

.e-schedule .e-schedule-toolbar .e-icon-schedule-pdf::before {
  content: "\e7c8";
  margin-left: -30px !important;
}

.custom-field-row {
  margin-bottom: 20px;
  margin-left: -30px !important;
}

.multi-prop div {
  padding-left: 0;
  padding-top: 0;
  margin-left: -30px !important;
}

.calendarview {
  margin-left: -30px !important;
}

.e-schedule .e-month-view .e-content-wrap {
  display: block !important;
}

table {
  width: 100% !important;
  margin-left: 0 !important;
}

.e-bigger.e-small .e-calendar,
.e-calendar {
  max-width: 320px !important;
  min-width: 275px !important;
}

/* Media Queries */

@media only screen and (max-width: 768px) {
  .control-section {
    margin: 0px !important;
    width: 100% !important;
  }

  .control-wrapper {
    width: 100% !important;
  }

  .myschedule {
    margin: 0px !important;
    padding: 10px !important;
    width: 100% !important;
  }

  .calendarview {
    margin-right: 30px !important;
  }
  .myviewdiective {
    margin-left: -100px !important;
  }

  .schedule-control-section {
    margin-left: -150px !important;
    padding-left: -100px !important;
  }

  .innerdirective {
    margin-left: -100px !important;
  }

  .e-content-table {
    margin-left: 70px !important;
    width: 100%;
    border: 1px solid black !important;
  }

  .e-outer-table {
    width: 90% !important;
  }

  @media only screen and (max-width: 400px) {
    .e-schedule-table {
      margin-left: 60px !important;
      width: 100%;
    }
  }
  @media only screen and (max-width: 300px) {
    .e-schedule-table {
      margin-left: 50px !important;
      width: 100%;
    }
    .e-header-icon-wrapper {
      padding-left: 40px !important;
    }
    .e-subject-wrap {
      padding-left: 60px !important;
    }
    .e-date-time {
      padding-left: 30px !important;
    }
  }
}
