input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: #e7e7e7;
  -webkit-box-shadow: 0 0 0px 1000px #e7e7e7 inset;
}

.PageWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.AppWrapper {
  flex-grow: 1;
}

.FooterWrapper {
  flex-shrink: 0;
}
