.dashboard-main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: filter 0.3s ease;
}

.dashboard-loadingSpinner {
  position: relative;
  top: 35vh;
  left: 22vw;
}

.dashboard-left-wrapper {
  position: relative;
  background-color: #c7c7c781;
}

.dashboard-sidebar-container {
  width: 60%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}

.dashboard-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}

.dashboard-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.dashboard-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.dashboard-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 2rem 1rem 1rem 0rem;
  text-align: left;
  font-size: 1.21875rem;
}

.dashboard-right-pannel {
  padding: 0 1rem;
  background-color: #c7c7c781;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  min-height: 100vh;
}

/* .hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: black;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
} */

.fade-in-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.5em;
  text-align: center;
}



.fade-in-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.5em;
  text-align: center;
}
.dashboard-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dashboard-sidebar-menu-ul-hide {
  display: none;
}

.dashboard-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
  width: 100%;
  text-align: left;
}

.dashboard-wallet-dialog-btns {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.dashboard-wallet-dialog-btns > button {
  border: none;
  padding: 0.5em 1em;
  font-size: 20px;
  border-radius: 9px;
  border: 1px solid grey;
}

.dashboard-wallet-dialog-close-btn {
  border: none;
  padding: 0.5em 1em;
  border-radius: 9px;
  background-color: #ffc107;
}

.dashboard-verifyOtp-button {
  font-family: "prompt", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #ffc107;
  letter-spacing: 1px;
  padding: 8px 16px;
  border: none;
  outline: none;
  border-radius: 15px;
  color: white;
  box-shadow: 0px 2px 5px 0px #888888;
}

.dashboard-verifyOtp-button:hover {
  background-color: #ebb105;
}

.dashboard-otp-buttons {
  box-shadow: 0px 2px 5px 0px #888888;
  font-family: "prompt", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  letter-spacing: 1px;
  padding: 8px 16px;
  border: none;
  outline: none;
  border-radius: 15px;
  color: black;
}

.dashboard-otp-buttons:hover {
  background-color: #fff;
}

.dashboard-input__field__dialogs {
  padding: 0.375em 0.75em;
  border: 1px solid black;
  border-radius: 15px;
}

.dashboard-activateTag-popup {
  font-family: "prompt", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}

.small__popup {
  margin: 2.3em !important;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

/* .small__popup > h3 {
  margin-bottom: 0rem !important;
} */

.small_pop_btn {
  /* padding: 0.5em 1em; */
  margin: 0rem 0rem 0.5rem 0rem;
  padding: 1rem 1rem 1rem 1rem;
  border: none;
  border-radius: 15px;
  background-color: #ffc107;
  font-size: 1.25rem;
  /* color: white; */
  box-shadow: 0px 2px 5px 0px #888888;
}

.wallet-dialog-content {
  text-align: center !important;
  padding: 2em 1.5em !important;
  max-width: 500px; /* Increase dialog width */
  width: 90%; /* Ensure responsiveness */
  margin: 0 auto;
}

.dialog-text {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.dialog-buttons-container {
  display: flex;
  justify-content: space-around;
  gap: 1em;
  margin-top: 1rem;
}

.dialog-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  font-size: 0.9rem;
  padding: 0.8em 1.2em;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute !important;
  top: 0rem !important; /* Position at the top */
  right: 0.2rem !important; /* Position at the right corner */
  z-index: 10 !important; /* Ensure it stays above other elements */
  background-color: transparent !important; /* Optional for a clean look */
  border: none !important;
  cursor: pointer !important;
  color: #555 !important;
  font-size: 1.5rem !important;
  transition: color 0.3s ease !important;
}

.close-button:hover {
  color: #000;
}

.android-btn {
  background-color: #a4c639;
  color: white;
}

.apple-btn {
  background-color: #d9d9d9;
  color: black;
}

.dialog-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.android-btn:hover {
  background-color: #8eb530;
}

.apple-btn:hover {
  background-color: #c4c4c4;
}

#inact {
  background-color: rgba(231, 227, 227, 0.6);
  border: none;
}

#act {
  background-color: #fcbc1b;
}

/* Style for active and inactive button ends */

.text-center {
  text-align: center;
}

/* New Dashboard Style begins */
ul {
  line-height: 30px;
}

.all-tags-div {
  text-align: center !important;
  margin-left: 9% !important;
}

.toggle-alltags {
  /* margin-left: -40px !important; */
  margin-left: 10% !important;
}

.user_img {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.user_img_icon {
  width: 7rem;
  height: 7rem;
}

.dashboard__blacksidenav {
  width: 23rem;
  background-color: #e7e4e4;
}

/* .tag_inside_btn {
  height: 3rem;
  width: 7rem;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #e7e4e4;
}

.tag_inside_btn:hover {
  background-color: rgb(251 249 249);
} */

/* .profile_name {
  padding-left: 4rem;
  color: black;
  width: 100%;
  text-decoration: none;
  list-style: none;
  display: flex;
  flex-direction: left;
  align-items: center;
  height: 2.3rem;
  font-size: 1.5rem;
  margin-bottom: 0.7rem;
  margin-top: 1rem;
} */

/* .profile_name:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
  color: black;
  text-decoration: none;
} */

/* .tag_inside_btn > span {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 3rem;
} */

/* .tag1_text {
  color: black;
  font-size: 17px;
  border: none;
  outline: none;
} */

/* .my-tags {
  height: 3rem;
} */

.my-tags1 {
  /* padding-left: 2rem; */
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: left;
  align-items: center;
}

.my-tags1:hover {
  cursor: pointer;
  background-color: rgb(251 249 249);
}

/* .right_pannel {
  margin: 2rem auto;
} */

/* .dashcontainer {
  width: 100%;
  height: 100vh;
  background-color: khaki;
  padding: 10px;
  margin-top: 10px;
} */

/* .sidenav-container1 {
  align-self: center;
  padding-right: 25px;
  line-height: normal;
  font-size: 9px;
  font-weight: 700;
  background-color: rgb(250, 250, 250);
  text-align: left;
  width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 150px 50px;
  color: black;
  padding: 20px;
  margin-left: 90px !important;
  margin-top: 0px !important;
} */

/* .mat-button-toggle-drawer {
  line-height: 1.6;
  color: black;
  float: left;
  margin-top: 50px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  height: 40px !important;
  font-weight: bold !important;
} */

button:focus {
  outline: none;
}

.my-tags1 {
  color: black;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: rgb(251 249 249);
  margin-left: -3px;
}

.my-tags {
  /* color: #fcbc1b; */
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1px;
}

/* .floatsidenav > li > button:focus {
  outline: none;
  border: none;
  color: #FCBC1B;
}

.floatsidenav > li > button {
  background-color: transparent;
  border: none;
}  */

/* .mybtn button {
  padding-left: 50px;
  padding-right: 50px;
} */

/* .intro-name-dashboard {
  margin-left: 0px;
} */

.user-logo {
  color: #212529 !important;
}

/* #activate-tag {
  background-color: #fcbc1b;
  box-shadow: 0px 2px 2px 0px #888888;
  padding: 5px 10px;
  margin: 20px 25px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
} */

/* #cancel-activation {
  background-color: white;
  box-shadow: 0px 2px 4px 0px #888888;
  padding: 5px 10px;
  margin: 20px 25px;
  margin-top: 0px;
  border: none;
  border-radius: 5px;
} */

/* #tag-number {
  padding: 5px 10px;
  margin: 20px 25px;
  background-color: white;
  border: 1px solid blue;
  border-radius: 2px;
} */

#dashboard-profile {
  color: #343a40 !important;
  font: 500 20px/32px Roboto, Helvetica Neue, sans-serif;
  font-weight: 700;
  letter-spacing: normal;
}

.dashboard-profile {
  color: #343a40 !important;
  text-align: center;
  font: 500 20px/32px Roboto, Helvetica Neue, sans-serif;
  font-weight: 700;
  letter-spacing: 30px;
}

.emptytags-btn {
  font: 500 14px/20px Roboto, Helvetica Neue, sans-serif;
  font-size: 20px;
  color: lightgray;
  background-color: inherit !important;
  border: none;
  outline: none;
  padding: 10px;
}

.emptytags-btn:hover {
  background-color: #f5f5f5 !important;
}

.qrbutton {
  width: 28px;
  margin-left: 1rem;
  margin-bottom: 2px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 2px rgb(148, 148, 148);
  border: 1px solid black;
  cursor: pointer;
}

.qrbutton:hover {
  transition: all 0.2s ease-in-out;
  border: 0;
}

.my_activate_tag {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1350px) {
  /* .sidenav-container1 {
    margin: 00px 00px !important;
    margin-left: -40px !important;
  } */

  /* .intro-name-dashboard {
    margin-left: 0px !important;
  } */

  .mytags1buttons {
    width: 100% !important;
    text-align: left !important;
  }

  .dashboard__blacksidenav {
    padding-left: 20px !important;
  }

  /* .mat-button-toggle-drawer {
    width: 100% !important;
  } */

  .float-1 {
    margin-left: -40px !important;
  }

  .float-2 {
    margin-left: -20px !important;
    width: 120% !important;
  }

  .float-3 {
    margin-left: -25px !important;
    width: 120% !important;
  }
}

/* @media only screen and (max-width: 1308px) {
  .tbuttonsgroup {
    width: 620px !important;
  }
}

@media only screen and (max-width: 1298px) {
  .tbuttonsgroup {
    width: 620px !important;
  }
} */

@media only screen and (max-width: 1308px) {
  /* .sidenav-container1 {
    margin: 00px 00px !important;
    margin-left: -50px !important;
  } */

  .right_pannel {
    /* background-color: bisque !important; */
    /* margin-left: 30px !important; */
    margin: auto !important;
    margin-top: 10px !important;
  }

  /* .intro-name-dashboard {
    margin-left: 0px !important;
  } */

  .mytags1buttons {
    width: 100% !important;
    text-align: left !important;
  }

  .dashboard__blacksidenav {
    padding-left: 20px !important;
  }
  /* 
  .mat-button-toggle-drawer {
    width: 100% !important;
  } */

  .float-1 {
    margin-left: -40px !important;
  }

  .float-2 {
    margin-left: -20px !important;
    width: 120% !important;
  }

  .float-3 {
    margin-left: -25px !important;
    width: 120% !important;
  }
}

/* @media only screen and (max-width: 1200px) {
  .tbuttonsgroup {
    width: 620px !important;
  }
} */

@media only screen and (max-width: 1175px) {
  .right_pannel {
    margin-left: 6rem;
  }

  /* .tbuttonsgroup {
    width: 620px !important;
  } */
}

@media only screen and (max-width: 1070px) {
  /* .tbuttonsgroup {
    width: 420px !important;
  } */

  .align-div {
    width: 100%;
  }
}

@media only screen and (width: 1024px) {
}

@media only screen and (max-width: 991px) {
  .left_pannel_options {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .my_tags_text {
    display: flex;
    width: 100%;
  }

  .my-tags {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list_links {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
  }

  .links_button {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .mytags1buttons {
    width: 100%;
  }

  /* .tbuttonsgroup {
    width: 100% !important;
  } */
  .dashboard__blacksidenav {
    position: unset !important;
  }

  .right_pannel {
    width: 90% !important;
  }
}

/* @media only screen and (max-width: 967px) {
   .sidenav-container1 {
    margin-left: 20px !important;
  } 

   .tbuttonsgroup {
    width: 720px !important;
  } 
} */

/* @media only screen and (max-width: 850px) {
  .tbuttonsgroup {
    width: 620px !important;
  }
} */

/* @media only screen and (max-width: 700px) {
  .tbuttonsgroup {
    width: 520px !important;
  }
} */

@media only screen and (max-width: 650px) {
  /* .tbuttonsgroup {
    width: 400px !important;
  } */
  /* .tbuttons {
    width: 200px !important;
    height: 40px !important;
  } */

  .float-1 {
    margin-left: -90px !important;
  }

  .float-2 {
    margin-left: -40px !important;
  }

  .float-3 {
    margin-left: -50px !important;
  }
}

/* @media only screen and (max-width: 500px) {
  .tbuttonsgroup,
  .alltbuttongrp {
    margin-left: 10% !important;
  }
} */

@media only screen and (width: 540px) {
  .toggle-alltags {
    margin-left: -20px !important;
  }
}
@media (max-width: 768px) {
  .dashboard-main-wrapper {
    top: 8.3rem;
    width: 100%;
  }
  .dialog-text {
    font-size: 0.8rem !important; /* Slightly smaller font size */
    margin-bottom: 1.5em !important;
    padding-top: 1.5rem !important;
  }
  .wallet-dialog-content {
    border-radius: 15px;
    padding: 0em;
  }

  .close-button {
    position: absolute !important;
    top: 0rem !important;
    right: 0rem !important;
    z-index: 10 !important;
    background-color: transparent !important;
    border: none !important;
    cursor: pointer !important;
    color: #555 !important;
    font-size: 1.5rem !important;
    transition: color 0.3s ease !important;
  }
}
/* @media only screen and (max-width: 450px) {
  .tbuttonsgroup {
    width: 280px !important;
    margin-left: -10% !important;
  } 
 .tbuttons {
    width: 160px !important;
    height: 40px !important;
  } 
} */

@media only screen and (max-width: 420px) {
  .my_activate_tag {
    width: fit-content !important;
    padding: 5px !important;
  }
}

/* @media only screen and (max-width: 400px) {
  .small__popup {
    width: 280px !important;
    padding: 10px !important;
  }

  .small__popup h1 {
    text-align: center !important;
  }
} */

@media only screen and (max-width: 377px) {
  .my_activate_tag {
    width: 280px !important;
    padding: 10px !important;
  }

  .my_activate_tag input {
    margin-top: -10px !important;
  }
}

@media only screen and (max-width: 350px) {
  .tbuttonsgroup {
    width: 260px !important;
    /* margin-left: 3% !important; */
    /* margin-left: -40px !important; */
  }

  .tbuttons {
    width: 130px !important;
    height: 40px !important;
  }

  /* .intro-name-dashboard {
    margin-left: 0px !important;
  } */
}

@media only screen and (max-width: 348px) {
  .small__popup button {
    width: 200px !important;
    margin: 5px auto !important;
  }

  /* .tbuttonsgroup {
    margin-left: -10% !important;
  } */
}

@media only screen and (max-width: 330px) {
  /* .sidenav-container1 {
    width: 260px !important;
    margin-left: 0px !important;
  } */

  .mytags1buttons {
    margin-top: -50px !important;
    height: 50px !important;
    width: 230px !important;
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 300px) {
  .my_activate_tag {
    width: 220px !important;
    padding: 10px !important;
  }
}

@media only screen and (max-width: 290px) {
  .my_activate_tag {
    width: 200px !important;
    padding: 5px !important;
  }

  .tbuttonsgroup {
    width: 240px !important;
    margin-left: -50px !important;
  }
}

@media screen and (min-width: 50em) {
  .dashboard-loadingSpinner {
    left: 38vw;
  }
}

@media screen and (min-width: 62.5em) {
  .dashboard-sidebar-toggle-btn-wrapper {
    display: none;
  }

  .dashboard-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 10rem 1rem 0rem 1rem;
  }

  .dashboard-right-pannel {
    padding: 6% 11% 0 21%;
  }
}

@media screen and (width: 64em) {
  .small__popup > h3 {
    font-size: 1.875rem;
  }

  .small_pop_btn {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 480px) {
  .dialog-text {
    font-size: 0.8rem !important; /* Even smaller font size for better fit */
    margin-bottom: 0em !important;
    padding-top: 0rem !important;
  }
    .wallet-dialog-content {
    border-radius: 15px;
    padding: 0em;
  }

  .close-button {
    position: absolute !important;
    top: 0rem !important;
    right: 0rem !important;
    z-index: 10 !important;
    background-color: transparent !important;
    border: none !important;
    cursor: pointer !important;
    color: #555 !important;
    font-size: 1.5rem !important;
    transition: color 0.3s ease !important;
  }
}
@media screen and (min-width: 78.125em) {
  .dashboard-sidebar-menu-link {
    font-size: 1rem;
  }

  .dashboard-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }

  .dashboard-loadingSpinner {
    top: 24vh;
    left: 42vw;
  }
}
