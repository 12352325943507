.willactive_text{
  font-size: medium;
}
.timer_block_div{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.petstatus_digital{
  top: 7.8rem;
  position: absolute;
  left: 1rem;
  font-size: 13px;
}
.time_type {
  font-size: small;
}
.time_instance_div {
  font-size: small;
}
.smallcounter-div {
  border: solid #888888;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
}
.mobile_counter {
  display: none;
}
.card_logo {
  width: 10%;
}
.card_details {
  width: 60%;
}
.card_status {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
}
.digital_icon {
  display: none;
}

.warranty_counter {
    position: absolute;
  }
.card_main_digital {
  background-color: whitesmoke;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 20px;
  position: relative;
  padding-bottom: 2rem;
  border-radius: 15px;
}

.card_main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 20px;
  position: relative;
}

.tagdetailsandicons {
  position: absolute;
  bottom: 0.8rem;
  right: -0.7rem;
  display: flex;
}
.tagdetailsandicons_digital {
  position: absolute;
  bottom: 0.8rem;
  right: -0.7rem;
  display: flex;
}

.card_main_div_digital {
  width: 100%;
  min-height: 130px;
  text-align: center;
  border-radius: 15px;
  margin-top: 0;
  padding-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  background-color: whitesmoke;
}

.card_main_div {
  width: 100%;
  min-height: 130px;
  text-align: center;
  border-radius: 15px;
  margin-top: 0;
  padding-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  background-color: whitesmoke;
}
.petname {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 10px;
}

.activatedeactivatebutton {
  border: none !important;
  outline: none !important;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* .loader {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
} */

.icons {
  display: flex;
  gap: 2.4rem;
}
.icons > a {
  cursor: pointer;
}

.imagedetails {
  display: flex;
  align-items: flex-start;
  position: relative;
  height: 228px;
}

.fas {
  font-size: 14px;
}

.petownerid {
  font-size: 13px;
}

.petactivationdate {
  font-size: 13px;
  margin-top: 5px;
}

.petstatus {
  font-size: 13px;
}
.petloststatus {
  font-size: 13px;
  color: red;
}
.menu-drop:hover {
  cursor: pointer;
}
.images-css {
  width: 1.5rem;
  height: 1.5rem;
}
.details-images-css {
  width: 1.5rem;
  height: 1.5rem;
}
.activeCard__confirm {
  flex: 0.5;
  width: 100%;
  font-family: "prompt", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #ffc107;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 15px;
  color: white;
  box-shadow: 0px 2px 5px 0px #888888;
}

.activeCard__confirm:hover {
  background-color: #ebb105;
}

.activeCard__cancel {
  box-shadow: 0px 2px 5px 0px #888888;
  width: 100%;
  flex: 0.5;
  font-family: "prompt", sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 15px;
  color: black;
}

.activeCard__cancel:hover {
  background-color: #fff;
}
.digital_lebal {
  font-size: 20px;
  margin-right: 0.1rem;
  color: black;
  font-weight: 600;
}

.lebal {
  font-size: 20px;
  margin-right: 0.1rem;
  color: black;
  font-weight: 600;
}
.qrscan-img {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
  align-items: flex-start;
}

.geniecardtext-ul {
  list-style: none;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  margin: 1rem 1rem;
}
.walletcardtext-ul {
  list-style: none;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  margin: 1rem 1rem 0rem -0.4rem;
}

.imagedetails {
  margin: 5px;
  display: flex;
  justify-content: flex-start;
}
.middle-content {
  width: 100%;
  margin: 0rem 0rem 0rem -1.5rem;
}
.middle-content_digital {
  width: 100%;
  margin: 0rem 0rem 0rem -1.5rem;
}

.petdetails {
  text-align: left;
}

@media only screen and (max-width: 1070px) {
  .activatedeactivatebutton {
    border: none !important;
    outline: none !important;
    height: 12px !important;
    width: 12px !important;
    border-radius: 50% !important;
  }
  .cardtext {
    margin-top: 130px !important;
    margin-left: -130px !important;
  }
  .imagedetails {
    align-items: flex-start !important;
  }
}

@media only screen and (max-width: 991px) {
  .card_main {
    margin-left: 0;
  }
  .card_main_digital {
    margin-left: 0;
  }
}

@media only screen and (max-width: 650px) {
  .bagcardtext {
    margin-top: 130px !important;
    margin-left: -130px !important;
  }
  .laptop-view {
    display: none;
  }
  .mobile-view-petstatus {
    position: absolute;
    left: 0.6rem;
    bottom: 2.8rem;
  }
  .mobile-view-petstatus-passport {
    position: absolute;
    left: 0.6rem;
    bottom: 2.5rem;
  }
  .digital_card_mobile-view-lostpetstatus {
    position: absolute;
    left: 0.6rem;
    top: 17rem;
    color: red;
    font-size: 13px;
  }
  .mobile-view-lostpetstatus {
    position: absolute;
    left: 0.6rem;
    bottom: 2.7rem;
    color: red;
  }
  .geniecardtext-ul {
    gap: 0.8rem !important;
    margin: 0.8rem 0rem 0rem 0rem !important;
  }
  .walletcardtext-ul {
    gap: 0.8rem !important;
    margin: 1rem 0rem 0rem -1rem !important;
  }
  .smallcounter_digital_2 {
    margin-top: 2rem;
  }
  .warranty_counter {
    margin-top: 2rem;
  }
  .willactive_text{
    font-size: small;
  }
 
  .smallcounter_digital {
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 650px) {
  .mobile-view {
    display: none;
  }

  .laptop-view {
    position: absolute;
    top: 0rem;
    right: 1rem;
  }
  .date-laptopview {
    display: none;
  }
}
@media screen and (max-width: 650px) and (min-width: 450px) {
  .mobile-view-petactivationdate {
    position: absolute;
    left: 0rem;
    bottom: 0rem;
  }
}

@media only screen and (max-width: 450px) {
  /* .imagedetails {
    width: 65%;
  } */
  .card_main_div {
    align-items: flex-start;
    height: 20rem;
  }
  .card_main_div_digital {
    align-items: flex-start;
  }
  .mobile_counter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .digital_icon {
    display: block;
  }
  .activedate-lebal {
    text-wrap: nowrap;
    color: #eaa250;
    font-size: 16px;
  }
  .activedate-lebal ~ span {
    text-wrap: nowrap;
    font-size: 15px;
  }
  /* .petactivedate-lebal {
    text-wrap: nowrap;
    color: #eaa250;
    font-size: 16px;
  }
  .petactivedate-lebal ~ span {
    text-wrap: nowrap;
    font-size: 15px;
  } */
  .flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .petactivedate-lebal {
    white-space: nowrap;
    color: #eaa250;
    font-size: 16px;
    margin-right: 5px;
  }

  .date-value {
    white-space: nowrap;
    font-size: 13px;
  }
  .petactive-lebal {
    text-wrap: nowrap;
    color: #eaa250;
    font-size: 16px;
  }
  .petactive-lebal ~ span {
    text-wrap: nowrap;
    font-size: 15px;
  }
  .digital_lebal {
    font-size: small;
  }
  .lebal {
    font-size: 16px;
  }
  .lebal-content {
    font-size: 15px;
    white-space: normal;
  }
  .digital_lebal-content {
    font-size: small;
    white-space: normal;
  }
  .tagdetailsandicons {
    gap: 0.7rem;
  }
  .tagdetailsandicons_digital {
    gap: 0.7rem;
    display: none;
  }
  .geniecardtext-ul {
    width: 100%;
  }
  .walletcardtext-ul {
      width: 100%;
    }
  .mobile-view-petactivationdate {
    position: absolute;
    left: 8.8rem;
    bottom: -0.5rem;
  }
}
@media only screen and (max-width: 390px) {
  .activedate-lebal ~ span {
    text-wrap: wrap;
  }
}
@media only screen and (min-width: 390px) and (max-width: 398px) {
  .lebal-content {
    white-space: normal;
  }
  .digital_lebal-content {
    white-space: normal;
  }
  .middle-content {
    margin-left: -7px;
  }
  .middle-content_digital {
    margin-left: -7px;
  }
}
@media only screen and (max-width: 390px) {
  .mobile-view-petactivationdate {
    bottom: -1.5rem;
    font-size: 10px;
  }
}

@media only screen and (max-width: 360px) {
  .card_main {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .card_main_digital {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .card_main_div {
    height: 22rem !important;
  }
  .mobile-view-petactivationdate {
    bottom: -2.5rem;
    font-size: 10px;
  }
}

@media only screen and (max-width: 332px) {
  .mobile-view-petactivationdate {
    bottom: -2.5rem;
  }
}
@media only screen and (max-width: 321px) {
  .mobile-view-petactivationdate {
    bottom: -3.5rem;
  }
}

@media only screen and (max-width: 308px) {
  .card_main_div {
    height: 335px !important;
  }
  .pet-img {
    width: 85px !important;
    height: 85px !important;
    margin-top: 2px !important;
  }
  .geniecardtext-ul {
    gap: 0.5rem;
  }
  .walletcardtext-ul {
    gap: 0.5rem;
  }
  .lebal {
    font-size: 14px;
  }
  .digital_lebal {
    font-size: small;
  }
  .lebal-content {
    font-size: 12px;
  }
  .digital_lebal-content {
    font-size: small;
  }
  .tagdetailsandicons {
    right: 0rem;
  }
  .tagdetailsandicons_digital {
    right: 0rem;
  }
  .activedate-lebal {
    text-wrap: nowrap;
    color: #eaa250;
  }
  .mobile-view-petactivationdate {
    left: 5.9rem;
    bottom: 1.2rem;
  }
  .middle-content {
    margin-left: -2rem;
  }
  .middle-content_digital {
    margin-left: -2rem;
  }

  .activedate-lebal {
    font-size: 14px;
  }
  .activedate-lebal ~ span {
    font-size: 12px;
  }
}
