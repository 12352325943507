.genie-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.human-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.content {
  text-align: center;
  margin: 0rem 0rem 5rem 0rem;
}

.custom-button,
.get-started-button {
  margin-top: 20px;
}
.custom-button {
  padding: 15px 30px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10rem;
}

.custom-button:hover {
  background-color: #333;
}
.connect-text {
  text-align: center;
}

.connect-text p {
  font-size: 1rem;
  margin: 0;
  font-weight: bold;
  font-size: 2rem;
  text-align: right;
}
.connect-text p:first-child {
  margin-bottom: 15px;
}
.connect-text p.user-name {
  font-weight: bold;
  font-size: 2rem;
  margin-top: 5px;
}
.content {
  text-align: center;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.user-image-human {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.user-image-human img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.description {
  margin-top: 35px;
  font-size: 1rem;
  color: #555;
}

.get-started-button {
  margin-top: 20px;
  padding: 15px 30px;
  background-color: #ffcc00;
  color: white;
  border: 2px solid #ffcc00;
  border-radius: 5px;
  cursor: pointer;
}

.get-started-button:hover {
  background-color: #ffcc00;
}

.question-section {
  margin: 3rem 18rem 0rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.feeling-lost,
.worried-text,
.elderly-text {
  font-size: 1.3rem;
  margin-top: 20px;
  /* word-break: break-all; */
}

.tracker-text {
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  color: #333;
}

.availability-text {
  font-size: 0.8rem;
  font-weight: light;
  color: #777;
  margin-top: 10px;
}

.feature-button {
  width: 20%;
  padding: 0.5rem;
  background: #1c1d2a;
  border-radius: 25px;
  float: left;
  margin-left: 10rem;
}

.feature-button h4 {
  color: white;
  font-size: 20px;
  font-family: "Inter";
  font-weight: 500;
  word-wrap: break-word;
  margin: 0;
}

.feature-box {
  width: 100%;
}

.users-location-history-box {
  width: 75%;
  height: auto;
  margin: 5rem auto;
  background: black;
  border-radius: 25px;
  /* padding: 20px; */
  overflow: hidden;
}

.users-location-history-content-box {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.users-location-history-title {
  color: white;
  font-size: 2rem;
  font-family: "Inter";
  font-weight: bold;
  word-wrap: break-word;
}
.text-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0rem 0rem 4rem 2rem;
}
.users-location-history-text {
  color: white;
  font-size: 20px;
  font-family: "Inter";
  font-weight: 400;
  word-wrap: break-word;
  text-align: left;
}

.users-location-history-image {
  margin-left: 20px;
}

.users-location-history-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  width: auto;
  max-height: 100%;
}

.users-sos-history-box {
  width: 75%;
  height: auto;
  margin: 5rem auto;
  background: black;
  border-radius: 25px;
  /* padding: 20px; */
  overflow: hidden;
}

.users-sos-history-content-box {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.users-sos-history-title {
  color: white;
  font-size: 2rem;
  font-family: "Inter";
  font-weight: bold;
  word-wrap: break-word;
}
.text-sos-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0rem 11rem 3rem -5rem;
}
.users-sos-history-text {
  color: white;
  font-size: 20px;
  font-family: "Inter";
  font-weight: 400;
  word-wrap: break-word;
  text-align: left;
}

.users-sos-history-image {
  margin-left: 20px;
}

.users-sos-history-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  width: auto;
  max-height: 100%;
}

@media only screen and (max-width: 770px) {
  .genie-nav {
    padding: 10px;
  }

  .human-container {
    height: auto;
  }

  .custom-button {
    margin-top: 2rem;
  }

  .users-location-history-box,
  .users-sos-history-box {
    width: 90%;
    margin: 3rem auto;
  }

  .users-location-history-content-box,
  .users-sos-history-content-box {
    flex-direction: column;
    align-items: stretch;
  }

  .users-location-history-image,
  .users-sos-history-image {
    margin-left: 0;
    margin-top: 1rem;
  }

  .text-content,
  .text-sos-content {
    margin: 0;
  }
  .question-section {
    margin: 1rem 1rem 0rem 1rem;
  }

  .question-section p {
    /* margin-bottom: 1rem; */
    line-height: 2rem;
  }

  .tracker-text p {
    line-height: 2rem;
  }
  .feature-button {
    width: 60%;
    margin-left: 1rem;
    padding: 0.2rem;
  }

  .feature-button h4 {
    text-align: center;
    font-size: 15px;
  }
  .users-location-history-text {
    font-size: 16px;
  }

  .users-location-history-title {
    font-size: 1.5rem;
  }

  .text-content {
    gap: 1rem;
    margin: 1rem 1rem -1rem 1rem;
  }

  .text-sos-content {
    gap: 1rem;
    margin: 0rem 1rem 1rem 1rem;
  }
}
