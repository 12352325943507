.keyicons {
  margin-top: -10px;
  margin-left: -80px;
}

@media only screen and (max-width: 650px) {
  /* .keycardtext {
    margin-top: 155px !important;
    margin-left: -130px !important;
  } */
}

@media only screen and (max-width: 500px) {
  .keyicons {
    width: 300px !important;
    margin-bottom: 30px !important;
    margin-top: 150px !important;
    margin-left: -235px !important;
  }
}

@media only screen and (max-width: 350px) {
  .keyicons {
    width: 300px !important;
    margin-bottom: 30px !important;
    margin-top: 150px !important;
    margin-left: -235px !important;
  }

  .keytagdetails {
    width: 150px !important;
    /* margin-top: -20px !important; */
    margin-left: -70px !important;
    text-align: left !important;
  }
}

/* @media only screen and (max-width: 1070px) {
  .keytagdetailsandicons {
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
  }
} */
