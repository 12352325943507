/* @import url("https://fonts.googleapis.com/css2?family=Diplomata+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Federant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gorditas&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fruktur&family=Permanent+Marker&display=swap");
@import url("https://db.onlinewebfonts.com/c/32070eac9c9ad4cb61f359f319bb34ac?family=AbsolutDB");
@import url("https://db.onlinewebfonts.com/c/69034ec1a721c306a74b043f4138a7ed?family=BellCent+NamNum+BT");
@import url("https://db.onlinewebfonts.com/c/0ad0845267e2516d7293374db9d949c5?family=KibbyBoldFont");
@import url("https://fonts.googleapis.com/css2?family=Acme&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Prompt&family=Ubuntu:wght@300&display=swap");
.vehicle_div{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  width: 50%;
  margin-left:15rem;
}
.scanpage_text {
  text-align: center;
}
.lg_screen_medical {
  display: block;
}
.mobile_screen_medical {
  display: none;
}
.medical_detials {
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  font-weight: bold;
}
.medical_detials p {
  text-align: center;
}
.contactdivlg span {
  color: black;
}
.contactdivlg p {
  color: black;
}
.contactdivlg div {
  color: black;
}
.swipabledrawer {
  display: none;
}
.deactive_text {
  width: 100%;
}
.returnreward-text {
  margin-bottom: 23px;
  margin-top: 0px;
}
.returnrewardboth-text {
  margin-bottom: -36px;
  margin-top: 0px;
  margin-left: 55px;
}
.tata-deactive-pasportlogo img {
  height: 175px;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: transparent !important;
}
.tata-deactive-details {
  text-align: center;
}
.footerof-tata {
  display: flex;
  align-items: center;
  justify-content: center;
}
.scan-bottom-div {
  border: 2px solid gray;
  border-radius: 20px;
  background: rgb(240, 248, 255);
  background: linear-gradient(
    180deg,
    rgba(240, 248, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  transition: all 0.5s;
  left: 0rem;
}
.tata-deactive-logo img {
  height: 60px;
}
.default-image {
  width: 10rem;
}

/* .conatct-div-block {
  border: 0.5px solid gray;
  border-radius: 10px;
  background-color: white;
  padding: 0.5rem;
} */
.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  border: solid gray 0.05px;
  border-radius: 20px 20px 0 0;
  background: linear-gradient(180deg, #f0f8ff, #fff);
}
.tata-deactive-details {
  margin-top: 1rem;
}
.tata-active-details {
  margin-top: 1rem;
  color: red;
}
.tata-nav {
  padding: 1rem;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  z-index: 3;
  background-color: white;
}
.tata-active-upper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  z-index: 1;
  flex-direction: column;
  position: relative;
}
.tata-active-heading {
  text-align: center;
}
.tata-active-subheading {
  text-align: center;
}

.tata-deactive-heading h1 {
  font-weight: bold;
}

.container {
  background: linear-gradient(to bottom, #fffdfe, #f4ebec9c, #f1f1f18c);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !; /* Optional: Add a box shadow for a 3D effect */
  /* padding: 20px; */
  width: 100% !important;
  max-width: 1200px !important;
  /* height: 100vh; */
  margin: 1rem auto !important;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  box-sizing: border-box !important;
}

.container-for-rows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}
.container-for-rows > .row1,
.container-for-rows > .row2 {
  flex: 1; /* Let each row take equal space within the container */
  max-width: 100%; /* Set maximum width to prevent overflowing on smaller screens */
  margin: auto;
}
.custom-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.reportfound-message {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.custom-bottom-div {
  position: fixed;
  bottom: -10rem;
  width: 100vw;
  padding: 5%;
  padding-bottom: 1rem;
}
.two-field-wrapper-passport {
  margin: 0rem 0rem 0rem 0rem;
}
.logo-header {
  text-align: center;
  margin: 3rem 2rem;
}
.reportfound-bottom-inner-div {
  display: flex;
  flex-direction: row;
  /* padding: 1rem 1rem 1rem 0rem; */
  justify-content: center;
  gap: 1em;
}
.imagelocation-image {
  border: 2px;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  display: block;
  margin: auto;
}

.reportfound-pet-bottom-inner-div {
  display: flex;
  flex-direction: row;
  /* padding: 1rem 1rem 1rem 0rem; */
  justify-content: center;
  gap: 1em;
}
.reportfound-message-header {
  letter-spacing: 0em !important;
  line-height: 1.2em !important;
  font-size: 1.2em !important;
  font-family: "prompt", sans-serif !important;
  margin: 0px !important;
}
.reportfound-vector-humangenie {
  height: 3rem;
}
.reportfound-vector {
  height: 2.2rem;
}
.reportfound-responsive-vector {
  height: 2.5rem;
}
.reportfound-responsive-vector-email-wp {
  height: 2.3rem;
}
.bordercss {
  border-right: 1px solid lightgray;
  padding: 1rem 2rem 1rem 1rem;
  height: 3em;
}
.emailbordercss {
  padding: 1rem 2rem 1rem 1rem;
}
.reportfound-message-content {
  letter-spacing: 0em !important;
  padding: 0.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin: 0px !important;
}

.reportfound-message-content-icon {
  font-size: 1.875rem;
}

.reportfound-message-content-name {
  letter-spacing: 1px;
  font-size: 1.625rem;
  font-family: "Acme", sans-serif;
}

.reportfound-message-ownerid {
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  margin: 0px !important;
}
.message-container {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  background: white;
}

.message-content {
  display: flex;
  align-items: center;
}

.content-icon {
  margin-right: 8px;
}

.owner-name {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pet-poster-background {
  width: 60%;
  height: 100%;
  margin: auto;
  border: 1px solid rgb(202, 167, 82);
  text-align: center;
}
.alert-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #fff; */
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  z-index: 10;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #828282;
  /* border-radius: 4px; */
  color: #e4b462;
  background-color: white;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.alert-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 100%;
  /* background-color: white; */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.alert-wrapper:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  /* background-color: whitesmoke; */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.alert-message {
  font-size: 18px;
  /* font-weight: bold; */
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.5rem;
  /* font-size: 1.25rem; */
  font-weight: bold;
  color: black;
}
.owner-details-p {
  border-right: 1px solid #80808080;
  height: 5rem;
  padding: 3rem 5rem 2rem 2rem;
  display: flex;
  align-items: center;
}
/* .owner-details-whatsapp-p {
  border-right: 1px solid #80808080;
  height: 5rem;
  padding: 3rem 2rem 2rem 6rem;
  display: flex;
  align-items: center;
}
.owner-details-email-p {
  border-right: 1px solid #80808080;
  height: 5rem;
  padding: 3rem 2rem 2rem 6rem;
  display: flex;
  align-items: center;
} */
.blurred-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  font-size: 1rem;
}
.alert-button {
  padding: 12px 24px;
  border: none;
  border-radius: 15px;
  background-color: #ffc107;
  letter-spacing: 1px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}
.header-font-key {
  font-family: "Acme", sans-serif;
  padding: 2rem;
  color: black;
}
.text-center {
  text-align: center;
}
.vector-key {
  max-width: 22px;
  max-height: 22px;
  margin-left: 2px;
  margin-right: 8px;
}
.owner-details-bottom {
  padding-top: 1rem;
}

.lost-info-text img {
  max-width: 30px;
  max-height: 30px;
  margin-left: 2px;
  margin-right: 8px;
}

.mat-card-image2 {
  /* border: 1px solid brown; */
  border-radius: 100px;
  height: 180px;
  width: 190px;
  margin: auto;
  background-color: white;
}

.genie-nav {
  padding: 1rem;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  z-index: 3;
}
.genie-nav-button-container {
  display: flex;
  align-items: center;
}

.genie-nav-button {
  margin-left: auto;
  padding: 0.5rem 1rem;
  background-color: #fcbc1b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.genie-login-button {
  margin-left: auto;
  padding: 0.5rem 1rem;
  background-color: #fcbc1b;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
a {
  /* color: rgb(202, 167, 82); */
  text-decoration: none;
}

.header-font {
  font-family: "Prompt", sans-serif !important;
  padding: 1rem !important;
  color: black !important;
}
.reportfound-header-font {
  font-family: "Prompt", sans-serif !important;
  padding: 1rem !important;
  color: black !important;
}
.header-font-valuable {
  font-family: "Ubuntu", sans-serif !important;
  padding: 0.5rem !important;
  color: black !important;
}
.text-font {
  font-size: 60px;
  float: center;
  margin: auto;
  margin-bottom: 30px;
  line-height: 70px;
}
.valuable-text-font {
  font-size: 30px;
  float: center;
  margin: -5rem 0rem 2rem 12rem;
  /* margin-bottom: 30px; */
  line-height: 70px;
}
.text-fontsize {
  font-size: 25px;
  float: center;
  margin: auto;
  margin-bottom: 30px;
  line-height: 70px;
  text-align: center;
}
.owner-details-bottom {
  border: 0.5px solid gray;
  transition: all 0.5s;
}
.mat-card {
  width: 80% !important;
  margin: auto !important;
  /* margin-top: 30px; */
  background: linear-gradient(
    to bottom,
    #fefefe,
    #f4ebec1a,
    #efefef2b
  ) !important;
  /* border: 1px solid #cca151; */
}

.mat-card-header {
  margin-left: 18%;
}

.lost-info-header {
  font-size: 35px;
  line-break: normal;
  line-height: normal;
  padding-top: 10px;
  padding-right: 15px;
}

.lost-info-text-key {
  font-size: 21px;
  border: 0.5px solid gray;
  padding: 1.2vw;
  width: 23vw;
  border-radius: 10px;
  font-family: "ABeeZee", sans-serif;
}
.lost-info-text {
  /* color: #cca151; */
  font-size: 21px;
  width: 22vw !important;
  text-align: center;
  background: white;
}

.lost-info-text-address {
  color: #cca151;
  font-size: 21px;
  margin-top: -10px !important;
}
.yellow-color {
  color: rgb(202, 167, 82);
}

.bottom-div-align {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (max-width: 1120px) {
  .lost-info-header-res {
    font-size: 28px;
  }
}

@media screen and (min-width: 992px) {
  .container {
    border: 1px solid #ddd;
  }
}
@media only screen and (max-width: 922px) {
  .lost-info-header-res {
    font-size: 22px;
    line-break: normal;
  }
  .postar-res {
    padding: 5px 10px;
    font-size: 10px;
  }
  .lost-info-text-key {
    width: 20vw;
    /* padding: 1rem; */
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 690px) {
  .lost-info-header-res {
    font-size: 20px;
    line-break: normal;
  }
  .main-img2 {
    text-align: center;
    padding-top: 6vh;
  }
  .bg-gray {
    background: linear-gradient(
      to bottom,
      #fefefe85,
      #f4ebec00,
      #f1f1f100
    ) !important;
  }
  .bordercss {
    border-right: none;
    padding: 1rem 2rem 1rem 2rem;
  }
  .emailbordercss {
    padding: 1.2rem 0rem 1rem 0.2rem;
  }
  .colorw {
    background: linear-gradient(
      to bottom,
      #fefefe00,
      #f4ebec00,
      #f1f1f100
    ) !important;
  }
  .colorw-passport {
    background: white;
    /* margin-bottom: -1rem; */
  }
  .flex {
    margin-top: 0;
  }
  .reportfound-bottom-div {
    position: fixed;
    bottom: -16rem;
    width: 100vw;
    padding: 5%;
    padding-bottom: 1rem;
    background: linear-gradient(
      to bottom,
      #f9eaea,
      #f3ecec,
      #f1e2e2fc
    ) !important;
  }
  .reportfound-bottom-inner-div {
    /* flex-direction: column; */
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0em !important;
    padding: 0rem 5rem 0rem 4rem !important;
  }
  .reportfound-bottom-inner-div div {
    flex-basis: calc(50% - 1.8rem);
    margin-bottom: 1rem;
  }
  .reportfound-pet-bottom-inner-div {
    /* flex-direction: column; */
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0em !important;
    padding: 1rem 4rem 1rem 4rem !important;
  }
  .reportfound-pet-bottom-inner-div a {
    flex-basis: calc(50% - 2.2rem);
    margin-bottom: 1.5rem;
  }
  .reportfound-owner-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mat-card-image2 {
    height: 130px !important;
    width: 130px !important;
    border: 5px solid white;
  }
  .owner-details-p {
    border-right: none;
    border-bottom: 1px solid #80808080;
    /* height: auto; */
    padding: 1.5rem 0.5rem;
    display: flex;
    align-items: center;
  }
  .owner-details-bottom p {
    padding-bottom: 1rem;
    font-size: 16px !important;
    font-weight: 100 !important;
  }
  .owner-details-bottom {
    position: fixed;
    bottom: -23.4rem;
    width: 100vw;
    padding: 5%;
    padding-bottom: 1rem;
    border-radius: 20px 20px 0 0;
  }
  .pabr-responsive {
    border: 0.5px solid gray;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    background-color: white;
  }
  .vector-responsive {
    max-height: "60px";
    max-width: "48px";
    margin: 9rem 1rem 9rem 0rem !important;
  }
  .vector-wp-responsive {
    max-height: "60px";
    max-width: "48px";
    margin: 9rem 7rem 9rem 0rem !important;
  }
  .vector-email-responsive {
    max-height: "60px";
    max-width: "48px";
    margin: 8rem 8rem 9rem -4rem !important;
  }
  .genie-nav {
    /* background-color: rgb(243, 243, 243); */
    background-color: transparent;
  }
  .header-font {
    padding-bottom: 0.8rem !important;
  }
  .main-img2 {
    text-align: center;
    position: absolute;
    top: 12rem;
    /* left: 48%; */
    margin-left: -13%;
    width: 100%;
    /* height: 60px; */
  }
  .img-container {
    padding-top: 5rem !important;
    margin-top: 2rem !important;
  }
  .lost-info-text {
    padding: 1.5vh !important;
    width: 48vw !important;
    background: white;
  }

  .text-font {
    line-height: 2rem !important;
    padding: 2rem;
    font-size: 33px;
    margin-top: 4rem;
  }
  .valuable-text-font {
    line-height: 2rem;
    padding: 2rem;
    font-size: 20px;
    margin: -4rem 1rem 1rem 1rem;
  }
  .lost-info-text img {
    max-width: 25px;
    max-height: 25px;
    margin-left: 2px;
    margin-right: 8px;
  }
  .lost-info-text-key {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 43vw;
    /* height: 10vh; */
  }
  .vector-key {
    max-width: 20px;
    max-height: 20px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .scan-bottom-div {
    position: fixed;

    width: 100vw;
    padding: 5%;
    left: 0rem;
    padding-bottom: 1rem;
    z-index: 1;
  }
  .scan-bottom-div.open {
    bottom: 0;
  }

  .scan-bottom-div.closed {
    position: fixed;
    bottom: -15.5rem;
    width: 100vw;
    padding: 5%;
    /* left: 0rem; */
    padding-bottom: 1rem;
  }
}
@media only screen and (max-width: 500px) {
  .lost-data-header {
    font-size: 30px !important;
  }
  .owner-details-bottom {
    position: fixed;
    bottom: -23.4rem;
    padding: 5%;
    padding-bottom: 2vh;
    border-radius: 20px 20px 0 0;
  }
  .main-img2 {
    text-align: center;
    position: absolute;
    top: 3rem !important;
    /* left: 48%; */
    margin-left: -44% !important;
    width: 100% !important;
  }
  .reportfound-bottom-div {
    position: fixed;
    bottom: -16rem;
    padding: 5%;
    padding-bottom: 2vh;
  }
  .scan-bottom-div {
    position: fixed;
    padding: 5%;
    left: 0rem;
    padding-bottom: 2vh;
  }
}
@media only screen and (max-width: 450px) {
  .owner-details-bottom {
    position: fixed;
    bottom: -23.4rem;
    padding: 5%;
    padding-bottom: 2vh;
    border: 0.5px solid gray;
    border-radius: 20px 20px 0 0;
  }
  .lost-info-text-key {
    width: 48vw;
  }
  .reportfound-bottom-div {
    position: fixed;
    bottom: -16rem;
    padding: 5%;
    padding-bottom: 2vh;
    border: 0.5px solid gray;
    border-radius: 20px 20px 0 0;
  }
  .scan-bottom-div {
    position: fixed;
    padding: 5%;
    padding-bottom: 2vh;
    border: 0.5px solid gray;
    border-radius: 20px 20px 0 0;
  }
}
@media only screen and (max-width: 400px) {
  .mat-card {
    width: 80% !important;
  }
  .text-font {
    line-height: 2.5rem;
  }
  .lost-info-header {
    font-size: 20px;
  }
  .bottom-text {
    padding: 0px 0px 5px 0px;
    font-size: 20px !important;
  }
  .owner-details-bottom {
    padding: 5%;
    padding-bottom: 1rem;
  }
  .two-field-wrapper-passport1 {
    margin: 1.8rem 0rem 0rem 0rem;
  }
  .two-field-wrapper-passport {
    margin: -2rem 0rem 0rem 0rem;
  }
  .two-field-wrapper-passport2 {
    margin: -0.2rem 0rem 0rem 0rem;
  }
  .timer-div {
    margin-top: -2rem !important;
  }
  .bottom-div.closed {
    position: fixed;
    bottom: -17rem;
    width: 100vw;
    padding: 5%;
    /* left: 0rem; */
    padding-bottom: 1rem;
  }
  .reportfound-bottom-inner-div {
    padding: 0 !important;
  }
  .scan-bottom-div {
    padding: 5%;
    padding-bottom: 0rem;
  }
}
@media only screen and (max-width: 298px) {
  .lost-info-header-respon {
    font-size: 18px;
  }
  .owner-details-bottom {
    bottom: -23.4rem;
  }
  .reportfound-bottom-div {
    bottom: -16rem;
  }
}
@media only screen and (max-width: 1035px) {
  .lost-info-detail {
    width: 100% !important;
    /* margin: 0rem -1.5rem; */
  }
}
.bg-image {
  background-image: url("https://storage.googleapis.com/pettag/qrtagdev/assets/pet_banners/POst Banner_11.jpg");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  min-height: 768px;
}

.allowlocationtext {
  font-size: 22px;
  margin-top: -45vh;
  animation: blinker 1s linear infinite;
  color: red;
}
.buttonforbutton {
  display: flex;
  /* align-items: center; */
  /* justify-content: space-around; */
}

.repFound-label {
  font-size: 12px;
}
.repFound-input {
  font-size: 14px !important;
  padding: 5px !important;
  border: 1px solid lightgrey;
}
.repFound-input:focus {
  font-size: 14px !important;
  padding: 5px !important;
  border: 1px solid lightgrey !important;
}
.confirm {
  background-color: #ffc107 !important;
}
.confirm:disabled {
  background-color: #d3d3d3 !important;
}
.cancel:focus {
  padding: 5px 10px;
  background-color: white;
  border: none;
  outline: none;
  box-shadow: 0px 2px 5px 0px #888888;
}

.lost-detail {
  /* width: 100%; */
  text-align: left;
  /* padding-bottom: 10px; */
}
.lost-data {
  font-size: 22px;
}
.repFound-input {
  width: 100% !important;
}
.lost-info-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1em;
  gap: 0em;
  /* margin: auto; */
  background: linear-gradient(
    180deg,
    rgba(240, 248, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 3;
  border-radius: 20px;
  left: 0rem;
  text-align: center;
}
.lost-data-header {
  font-size: 55px;
  text-align: center;
  margin-top: 30px;
}
.lost-container {
  padding-top: 15px;
  display: flex !important;
  align-items: center;
}
.flex {
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  justify-content: center;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1130px) {
  .bg-image {
    min-height: 100vh;
  }
}

@media only screen and (max-width: 850px) {
  .bg-image {
    background-size: 100% 100vh;
    min-height: 600px;
  }

  .petfoundbutton {
    font-size: 16px !important;
    margin-top: 20px !important;
  }

  .allowlocationtext {
    margin-top: -50vh;
  }

  .buttonforbutton {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

@media only screen and (min-width: 770px) {
  .mobnum__div {
    margin-left: 15px !important;
  }
  .counrty__div {
    margin-right: 25px !important;
  }
}

@media only screen and (max-width: 770px) {
  .row2 {
    margin-top: -30px !important;
  }

  .report_found_email {
    margin-top: -10px !important;
  }
  .mob-number {
    margin-top: 13px !important;
  }
  .email-rep-found {
    margin-top: -5px !important;
  }

  .my-d-btn {
    margin-top: 10px !important;
  }
}
@media (max-width: 767px) {
  .container-for-rows {
    flex-direction: column; /* Stack rows vertically on smaller screens */
    align-items: center; /* Center the rows vertically */
  }

  .container-for-rows > .row1,
  .container-for-rows > .row2 {
    flex-basis: 100%; /* Take full width for each row on screens smaller than 768px */
  }
}
@media only screen and (max-width: 768px) {
  .lost-detail {
    text-align: center !important;
    padding-bottom: 10px;
  }
  .row2 {
    flex-direction: column !important;
  }
  .mobile-num {
    margin-top: 30px !important;
  }

  .mob-num {
    display: flex !important;
  }

  .inner-dialog-div {
    width: 285px !important;
  }
  .container-for-rows > .row1,
  .container-for-rows > .row2 {
    flex-basis: 50%;
  }
  /* .emerd-dilog{
        width: 350px !important;
    } */

  /* .row2{
        flex-direction: column;
    }
    .ch-cont-code{
        width: 100% !important;
    }
     */
  .lost-data-header {
    margin-top: 20px;
  }
  .allowlocationtext {
    margin-top: -50vh;
  }
  .container {
    width: 90% !important;
    margin: auto !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 700px) {
  .allowlocationtext {
    margin-top: -40vh;
  }
}

@media only screen and (max-width: 650px) {
  .bg-image {
    background-size: 100% 100%;
    /* height: 400px !important; */
  }

  .allowlocationtext {
    margin-top: -16rem;
    font-size: 20px;
  }
}

@media only screen and (max-width: 487px) and (min-width: 480px) {
  /* .buttonforbutton button {
    width: 170px !important;
  } */
}

@media only screen and (max-width: 480px) {
  .bg-image {
    background-size: 100% 100%;
    height: 100%;
  }

  .allowlocationtext {
    margin-top: -140px;
    font-size: 18px;
  }

  .major-pet-med-pro-detail {
    margin-top: -40px !important;
  }

  .my-d-btn {
    margin-bottom: 18px !important;
  }

  .petfoundbutton {
    font-size: 14px !important;
    height: 65px !important;
    width: 100px !important;
    padding: 0px !important;
    word-wrap: break-word !important;
    white-space: normal !important;
  }

  .buttonforbutton {
    margin-top: -40px !important;
  }

  .buttonforbutton .petfoundbutton {
    margin-top: 0px !important;
  }

  .buttonforbuttonsecond {
    margin-top: -260px !important;
  }

  .my-d-btn {
    flex-direction: column !important;
    align-items: center !important;
  }

  .confirm,
  .cancel {
    width: 180px !important;
    padding: 4px 8px !important;
    margin: 5px 10px !important;
    height: fit-content !important;
  }

  .inner-dialog-div {
    width: 220px !important;
  }

  .allowlocationtext {
    margin-top: -70vh;
  }

  .loc-buttonforbutton {
    margin-top: 30px !important;
  }
  .reportfound-bottom-inner-div {
    /* flex-direction: column; */
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0em !important;
    /* padding: 1rem 4rem 0rem 4rem !important; */
  }
  .reportfound-bottom-inner-div div {
    flex-basis: calc(50% - 1.8rem);
    margin-bottom: 1rem;
  }
  .footer-font {
    width: 6rem;
    height: 6rem;
  }
}

@media only screen and (min-width: 690px) {
  .cancel-policy-drawer {
    width: 40%;
  }
}
@media only screen and (max-width: 350px) {
  .bg-image {
    background-size: 100% 200px !important;
    height: 200px !important;
  }

  .allowlocationtext {
    margin-top: -50vh;
    font-size: 16px;
  }

  .petfoundbutton {
    font-size: 13px !important;
    height: 60px !important;
    width: 90px !important;
    padding: -10px !important;
  }

  .buttonforbutton {
    margin-top: -335px !important;
  }

  .buttonforbuttonsecond {
    margin-top: -330px !important;
  }

  .loc-buttonforbutton {
    margin-top: 0px !important;
  }
  .loc-buttonforbutton button {
    width: 80px !important;
    height: 50px !important;
  }

  .major-pet-med-pro-detail .allowlocationtext {
    margin-top: -345px !important;
    font-size: 11px !important;
  }
}
@media (max-width: 576px) {
  .container {
    width: 95% !important;
    margin: auto !important;
    height: 100vh !important;
  }
}
@media only screen and (max-width: 296px) {
  .loc-buttonforbutton button {
    width: 105px !important;
    height: 45px !important;
    margin-top: -10px !important;
    padding-top: 2px !important;
  }

  .major-pet-med-pro-detail .allowlocationtext {
    margin-top: -350px !important;
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 288px) {
  .loc-buttonforbutton button {
    width: 98px !important;
    height: 44px !important;
    padding-top: 0px !important;
    margin-top: -5px !important;
  }
}

@media screen and (max-width: 310px) and (min-width: 270px) {
  .buttonforbutton {
    display: flex !important;
    flex-direction: row !important;
  }

  .buttonforbutton {
    justify-content: center !important;
  }

  .btn-div-1 button {
    margin-left: -20px !important;
  }

  .btn-div-2 .petfoundbutton {
    margin-right: -30px !important;
  }
}

/* Media Queries For Popups */

@media only screen and (max-width: 410px) {
  .activetag__PopUp {
    width: 250px !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  .activetag__PopUp h1 {
    text-align: center !important;
  }
}

@media only screen and (max-width: 358px) {
  .activetag__PopUp {
    width: 280px !important;
  }
}

@media only screen and (max-width: 305px) {
  .activetag__PopUp {
    width: 220px !important;
  }
}

@media screen and (width: 48em) {
  .reportfound-message-header {
    letter-spacing: 1px;
  }
}

@media screen and (width: 51.25em) {
  .reportfound-message-header {
    letter-spacing: 1px;
  }
}

@media (min-width: 320px) and (max-width: 450px) {
  .container-for-rows {
    gap: 10px; /* Reduce the gap between rows on smaller screens */
  }
  .custom-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .custom-container img {
    height: auto;
  }
  .imagelocation-image {
    height: 173px !important;
    width: 180px !important;
  }
  .container-for-rows > .row1,
  .container-for-rows > .row2 {
    flex-basis: 100%; /* Take full width for each row on screens between 375px and 450px */
  }

  .container-for-rows {
    flex-direction: column; /* Stack columns vertically on smaller screens */
    align-items: center; /* Center the columns horizontally */
    gap: 10px; /* Reduce the gap between columns on smaller screens */
  }
  .bordercss {
    border-right: none;
    padding: 1rem 1rem 0rem 1rem;
  }
  .emailbordercss {
    padding: 1.2rem 0rem 1rem 0.2rem;
  }
}

.timer-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: -0.8rem;
}

.timer-day-div {
  width: 18%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}
.timer-hrs-div,
.timer-mins-div,
.timer-secs-div {
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.2rem;
}

.timer-dot-div {
  width: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
  margin-top: -26px;
}
.timer-dot-div-1,
.timer-dot-div-2 {
  width: 5px;
  height: 5px;
  background: black;
}
.timer-value {
  background: black;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 2rem;
  font-weight: 900;
  font-size: 1rem;
  align-items: center;
  background: black;
  color: white;
}
.timer-info {
  font-size: 12px;
}

.mt-0 {
  margin-top: 0 !important;
}

.contact-suprt-btn {
  background: linear-gradient(
    180deg,
    rgba(240, 248, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 5% 5% 0% 5%;
}

@media only screen and (max-width: 768px) {
  /* Your CSS rules for mobile screens here */

  .deactive_text {
    padding: 4%;
  }
  .deactive_timer_etag{
    margin-bottom: 4rem;
    
  }
}

@media (max-width: 767px) {
  /* Styles for screens up to 767px wide (commonly used for mobile devices) */
  .vehicle_div{
    width: 100%;
    margin-left: 0rem;
  }
  .tata-deactive-heading {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .tata-deactive-heading h1 {
    font-size: 26px;
  }
  .tata-deactive-details {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .tata-active-details {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .tata-active-subheading {
    text-align: unset;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .tata-active-subheading h1 {
    font-size: 25px;
  }
  .tata-active-heading h1 {
    font-size: 26px;
  }
  .tata-active-details {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .tata-deactive-details {
    text-align: unset;
  }
  .swipabledrawer {
    display: block;
  }
  .swipabledrawer span {
    color: black;
  }

  .swipabledrawer p {
    color: black;
  }
  .returnreward-text {
    margin-bottom: 0px;
    margin-top: 6px;
    margin-left: 13px;
  }
  .returnrewardboth-text {
    margin-bottom: 0px;
    margin-top: 6px;
    margin-left: 13px;
  }
  .lg_screen_medical {
    display: none;
  }
  .mobile_screen_medical {
    display: block;
  }
  .footer_scanpage {
    display: none;
  }
  .scanpage_text {
    text-align: left;
  }
}
@media (max-width: 600px) {
  .tabs-container {
    padding: 4px;
  }
  .tab {
    min-width: 100%;
  }
}
