/*---------------- digital passport card styling ----------------------------- */
.passport_warning{
  text-align: center;
  padding-bottom: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
}
.warning_text_desktop{
  color: red;
  font-size: small;
}
.warning_text{
  color: red;
  font-size: small;
  display: none;
}
@media only screen and (max-width: 350px) {
  .warning_text_desktop{
    display: none;
  }
  /* CSS rules for mobile screens specfic for galaxy s8 */
  .passport_warning{
padding-top: 2.5rem;
}
.warning_text{
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .warning_text_desktop{
    display: none;
  }
  .warning_text{
    display: block;
  }
  /* CSS rules for mobile screens */
  .passport_warning {
    padding-top: 2.5rem;
      /* Any other mobile-specific styles */
  }
}

/* ------------- digital passport card styling ends ---------------------------- */



.keyicons {
  margin-top: -10px;
  margin-left: -55px;
}

@media only screen and (max-width: 650px) {
  .keycardtext {
    margin-top: 155px !important;
    margin-left: -130px !important;
  }
  .keytagdetailsandicons {
    margin-right: 0px !important;
    margin-top: 0px !important;
  }
  .keyicons {
    margin-top: 0px !important;
  }

  .keyicons {
    width: 300px !important;
    margin-bottom: 30px !important;
    /* margin-top: 170px !important; */
    margin-left: -90px !important;
  }
  .keytagdetails {
    width: 150px !important;
    margin-top: -250px !important;
    margin-left: 180px !important;
    text-align: left !important;
  }
}

@media only screen and (max-width: 500px) {
  .keytagdetailsandicons {
    margin-left: 50px !important;
    margin-top: -30px !important;
  }
  .keyicons {
    width: 300px !important;
    margin-bottom: 30px !important;
    margin-top: 200px !important;
    margin-left: -140px !important;
  }
  /* .card_main {
    width: 220px !important;
    margin-left: 80px !important;
  } */
}

@media only screen and (max-width: 450px) {
  .keytagdetailsandicons {
    margin-left: 50px !important;
    margin-top: -30px !important;
  }
  /* .keyicons{
    width: 300px !important;
    margin-bottom: 30px !important;
    margin-top: 200px !important;
    margin-left: -140px !important;
    background-color: rebeccapurple !important;
  } */
  .keyicons {
    width: 300px !important;
    margin-bottom: 30px !important;
    margin-top: 5px !important;
    margin-left: -135px !important;
  }
  .keytagdetails {
    width: 150px !important;
    margin-top: 20px !important;
    margin-left: -70px !important;
    text-align: left !important;
  }
  .card_main {
    /* width: 280px !important; */
    /* margin-left: 80px !important; */
    margin: auto !important;
    margin-bottom: 35px !important;
  }
  .passacticationdate{
    left: 0;
    bottom: 0;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 321px) {
  .passacticationdate {
   bottom: -3.5rem;
  }
}

@media only screen and (max-width: 350px) {
  .keyicons {
    width: 300px !important;
    margin-bottom: 30px !important;
    margin-top: 10px !important;
    margin-left: -135px !important;
  }
  .keytagdetails {
    width: 150px !important;
    margin-top: 20px !important;
    margin-left: -70px !important;
    text-align: left !important;
  }
  /* .card_main {
    width: 260px !important;
    margin: auto !important;
    margin-bottom: 35px !important;
    margin-left: 3% !important;
  } */
}
