/* @import url("https://fonts.googleapis.com/css2?family=Diplomata+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Federant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gorditas&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fruktur&family=Permanent+Marker&display=swap");
@import url("https://db.onlinewebfonts.com/c/32070eac9c9ad4cb61f359f319bb34ac?family=AbsolutDB");
@import url("https://db.onlinewebfonts.com/c/69034ec1a721c306a74b043f4138a7ed?family=BellCent+NamNum+BT");
@import url("https://db.onlinewebfonts.com/c/0ad0845267e2516d7293374db9d949c5?family=KibbyBoldFont");
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap"); */
.scan_pagetimer {
  padding-top: 0rem !important;
  padding-bottom: 1rem;
}
.footerof-tata span:hover {
  color: inherit;
}
.footerof-tata div:hover {
  color: inherit;
}

.tata-welcome-main {
  background: linear-gradient(to bottom, #fffdfe, #f4ebec9c, #f1f1f18c);
}
.tata-uperpart {
  height: 30%;
}
.tata-welcome-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 2rem;
}

.tata-logo-div img {
  height: 80px;
}
.tata-lowerpart {
  height: 70%;
}
.tata-activesoon-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}
.tata-activesoon-div h1 {
  font-weight: bold;
}
.tata-details {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}
.tata-details p {
  max-width: 81%;

  color: red;
  font-weight: bold;
}
.tata-footer {
  margin: 0rem;
  padding: 0.8rem 4.2rem;
}

.nav {
  padding: 1rem;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  z-index: 3;
}

.download-btn-apk {
  color: blue;
  font-size: 11px;
}
.download-btn-apk:hover {
  color: #ffc107 !important;
  text-decoration: none;
}

.row-center {
  align-items: center;
}

.image-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.mat-card-welcome {
  width: 80% !important;
  margin: auto;
  margin-top: 20px;
  border: 1px solid #ddd;
}

.header-font {
  font-family: "Acme", sans-serif;
  padding: 2rem;
  color: black;
}

.header-text {
  font-size: 80px;
  float: center;
  margin: auto;
  margin-bottom: 30px;
  line-height: 50px;
}

.img-container {
  text-align: center;
}

.mat-card-image3 {
  height: 80px;
  width: 150px;
  margin: auto;
  padding: 1rem;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lost-info-text2 {
  font-size: 21px;
  margin: 2vw;
  border: 0.5px solid gray;
  padding: 1.2vw;
  border-radius: 10px;
  font-family: "prompt", sans-serif;
}

.lost-info-text2-passport {
  font-size: 21px;
  /* margin: 2vw; */
  border: 0.5px solid gray;
  padding: 1.2vw;
  border-radius: 10px;
  font-family: "prompt", sans-serif;
}

.lost-info-text1-passport {
  font-size: 21px;
  /* margin: 2vw;
  border: 0.5px solid gray; */
  padding: 1.2vw;
  border-radius: 10px;
  font-family: "prompt", sans-serif;
}

.bottom-div2 {
  background: rgb(240, 248, 255);
  background: linear-gradient(
    180deg,
    rgba(240, 248, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 2rem;
  padding: 2rem;
  padding-bottom: 0;
}

.bottom-inner-div {
  display: flex;
  /* padding: 1rem 1rem 1rem 0rem; */
  justify-content: space-around;
  gap: 3em;
}
.timer-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding-top: 2rem;
}
.timer-div_digital {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding-top: 0.3rem;
}

.timer-day-div {
  width: 18%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}
.timer-hrs-div,
.timer-mins-div,
.timer-secs-div {
  width: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.2rem;
}

.timer-dot-div {
  width: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
  margin-top: -26px;
}
.timer-dot-div-1,
.timer-dot-div-2 {
  width: 5px;
  height: 5px;
  background: black;
}
.timer-value {
  background: black;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 2rem;
  font-weight: 900;
  font-size: 1rem;
  align-items: center;
  background: black;
  color: white;
}
.timer-info {
  font-size: 12px;
}
.modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal-box {
  border: 1px solid #000;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-button {
  padding: 10px 20px;
  margin-top: 15px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.modal.open {
  display: block;
}

.modal.closed {
  display: none;
}

.modal-content p {
  font-size: 1.5rem;
  color: #ffb104;
  margin-bottom: 20px;
}

.mt-0 {
  margin-top: 0 !important;
}
.pabr {
  border: 0.5px solid gray;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: white;
}

.lost-info-text2 img,
.footer-font img,
.welcome-footer-font img {
  max-width: 22px;
  max-height: 22px;
  margin-left: 2px;
  margin-right: 8px;
}
.welcome-footer-font {
  font-family: "prompt", sans-serif;
}
.footer-font {
  font-family: "prompt", sans-serif;
  /* width: 6rem;
  height: 6rem; */
}

.google__button2 {
  border: none;
  border-radius: 10px;
  color: black;
  background-color: white;
  /* font-weight: 600; */
}

.vector {
  max-width: 22px;
  max-height: 22px;
  margin-left: 2px;
  margin-right: 8px;
}

@media only screen and (max-width: 768px) {
  .img-container {
    text-align: left;
    padding: 2rem;
    padding-top: 0;
  }

  .main-img {
    text-align: center;
  }

  .flex {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 690px) {
  .nav {
    background-color: transparent;
  }

  .header-font {
    padding-bottom: 0;
  }

  .lost-valuable {
    margin-bottom: 0px;
  }

  .header-text {
    /* line-height: 0rem; */
    padding: 2rem;
    font-size: 40px;
    margin-top: 5rem;
  }

  .bg-gray {
    background-color: rgba(243, 243, 243);
  }

  .mat-card-welcome {
    border: none;
    width: 100% !important;
    margin: 0;
    margin-bottom: 13rem;
  }

  .img-container {
    text-align: left;
    padding: 2rem;
    padding-top: 0;
    background-color: white;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }

  .colorw {
    background-color: white !important;
  }

  .main-img {
    text-align: center;
    position: absolute;
    top: -70px;
    left: 48%;
    margin-left: -10%;
    width: 60px;
    height: 60px;
  }

  .mat-card-image3 {
    width: 110px;
    /* border: 5px solid white; */
    /* background-color: white; */
    border-radius: 15px;
    /* box-shadow: 0 0 6px 1px gray; */
  }

  .pa {
    padding-bottom: 2rem;
  }

  .nav {
    display: none;
  }

  .flex {
    margin-top: 0rem;
  }

  .lost-info-text2 {
    margin: -4rem 0rem 0rem 0rem;
    padding: 4vw;
    line-height: 2rem;
  }
  .lost-info-text2-passport {
    padding: 4rem;
    line-height: 2rem;
    background: white;
  }
  .lost-info-text1-passport {
    padding: 0rem 0rem 2rem 0rem;
    line-height: 2rem;
    background: white;
  }
  p {
    margin-bottom: 0.4rem;
  }

  .bottom-div2 p {
    padding-bottom: 1rem;
    font-size: 16px !important;
    font-weight: 100;
    font-family: "prompt", sans-serif;
  }

  .bottom-div2 {
    position: fixed;
    /* bottom: 0rem;  */
    margin: 21rem 0rem 0rem 0rem !important;
    width: 100vw;
    padding: 2rem;
    padding-bottom: 1rem;
  }

  .bottom-inner-div {
    flex-direction: column;
    justify-content: center;
    gap: 0.5em;
  }

  .flex-between {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
  }

  .lost-info-text2 img,
  .footer-font img,
  .welcome-footer-font img,
  .vector {
    max-width: 20px;
    max-height: 20px;
    margin-left: 2px;
    margin-right: 2px;
  }

  .footer-font {
    text-align: left;
  }
  .welcome-footer-font {
    text-align: left;
  }
  .google__button2 {
    width: 100%;
    text-align: left;
    font-weight: normal;
  }

  .bottom-inner-div {
    padding: 0;
  }
}

@media only screen and (max-width: 500px) {
  .main-img {
    text-align: center;
    position: absolute;
    left: 45%;
    margin-left: -10%;
    width: 60px;
    height: 60px;
  }

  .bottom-div2 {
    position: fixed;
    /* bottom: 0rem;  */
    margin: auto !important;
    padding: 5%;
    padding-bottom: 2vh;
  }
}

@media only screen and (max-width: 450px) {
  .main-img {
    text-align: center;
    position: absolute;
    left: 42%;
    margin-left: -8%;
    width: 60px;
    height: 60px;
  }

  .bottom-div2 {
    position: fixed;
    /* bottom: 0rem;  */
    margin: 17rem 0rem 0rem 0rem !important;
    padding: 5%;
    padding-bottom: 2vh;
  }
}

@media only screen and (max-width: 400px) {
  .mat-card-welcome {
    width: 100% !important;
  }

  .bottom-div2 {
    margin: 5rem 0rem 0rem 0rem !important;
  }
}
@media only screen and (max-width: 390px) {
  .bottom-div2 {
    margin: 13rem 0rem 0rem 0rem !important;
  }
}

/* Additional media query for 375px */
@media only screen and (max-width: 375px) {
  .bottom-div2 {
    margin: 4rem 0rem 0rem 0rem !important;
  }
}
@media only screen and (max-width: 298px) {
  .mat-card-welcome {
    width: 100% !important;
  }

  .bottom-div2 {
    margin: 5rem 0rem 0rem 0rem !important;
  }
}

@media only screen and (min-width: 48em) {
  .bg-gray {
    background-color: transparent !important;
  }
}

@media (max-width: 380px) {
  /* Your styles for screens with resolution 320px x 658px  galaxys9+*/
  .tata-active-upper {
    margin-top: 8rem;
  }
  .tata-active-passportlogi {
    margin-top: 1rem;
  }
  .tata-active-subheading {
    margin-top: 2rem;
  }
  .tata-footer {
    margin: 0px;
    padding: 3rem 0rem 4rem;
  }
  .tata-activesoon-div {
    padding-top: 4rem;
    padding-bottom: 3rem;

    margin-left: 1rem;
  }
  .tata-activesoon-div h1 {
    font-size: 23px;
    text-align: center;
    padding-left: 0rem;
    padding-right: 1rem;
  }
  .timer-hrs-div,
  .timer-mins-div,
  .timer-secs-div {
    width: 12%;
  }
  .scan_pagetimer {
    padding-top: 2rem !important;
    padding-bottom: 3rem;
  }
}

@media (max-width: 767px) and (min-width: 380px) {
  /* Styles for screens up to 767px wide (commonly used for mobile devices) */
  .tata-active-upper {
    margin-top: 8rem;
  }
  .tata-active-passportlogi {
    margin-top: 1rem;
  }
  .tata-active-subheading {
    margin-top: 2rem;
  }
  .tata-footer {
    margin: 0px;
    padding: 3.5rem 0rem 4rem;
  }
  .tata-activesoon-div {
    padding-top: 4rem;
    padding-bottom: 3rem;

    margin-left: 1rem;
  }
  .tata-activesoon-div h1 {
    font-size: 23px;
    text-align: center;
    padding-left: 3rem;
    padding-right: 4rem;
  }
  .timer-hrs-div,
  .timer-mins-div,
  .timer-secs-div {
    width: 12%;
  }
  .scan_pagetimer {
    padding-top: 2rem !important;
    padding-bottom: 3rem;
  }
}
