.basicdetailsdiv {
  background-color: white;
}

.mobileinformation {
  display: none;
}
.mtopbar {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  background-color: whitesmoke;
}

.subbutton {
  padding: 5px 10px;
  margin: 10px 20px;
  margin-bottom: 35px;
  flex: 0.5;
  border: none;
}

.contactdivlg {
  margin: 0rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.conatct-div-block:hover {
  color: #007bff;
}
.contactdivlg-human {
  margin: 1rem;
}
.namediv {
  width: 50%;
}
.namediv {
  width: 50%;
}
.scrollablediv {
  overflow-y: scroll;
  height: 400px;
}
.doctorcontactlg {
  display: block;
  width: 100%;
}
.doctorcontactsm {
  margin-bottom: 1rem;
}
.idcard {
  background-color: white;
  display: flex;
  box-shadow: 0px 0px 5px 1px rgba(179, 179, 179, 0.75);
  align-items: center;
  width: 70%;
  border-radius: 20px;
}
.reportfoundusername {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}
.twodiv {
  display: flex;
}
.contactdiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}
.basicdetailstab {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.imgcount {
  display: flex;
  align-items: center;
  justify-content: center;
}
.detailsbox {
  font-size: 20px;
}
.tag-number {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headingtext {
  font-size: 60px;
  float: center;
  margin: auto;
  margin-bottom: 30px;
  line-height: 70px;
}

.imagebox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 5rem;
}

.guardianmobile {
  display: none;
}
.medicaltitle {
  margin-left: 1rem;
}
.medicalcount {
  font-size: 25px;
  padding: 10px 0;
  margin-right: 0.5rem;
}
.medicalimg {
  width: 2em;
  height: 2em;
}
.box {
  border: 3px solid #ccc;
  border-radius: 10px;
  padding: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.drawer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(240, 248, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border: 0.5px solid gray;
  border-radius: 20px;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.guardianinfo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.drawerarrow {
  display: none;
}
.details {
  padding: 0.5rem;
}
.detailsbox {
  padding: 1rem;
}
.label {
  color: black;
  font-weight: 600;
}
.mobiletopbar {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;

  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  display: none;
}
@media screen and (max-width: 767px) {
  .guardianmobile {
    display: flex;
  }
  .mtopbar {
    display: none;
  }
  .mobiletopbar {
    display: block;
    background-color: whitesmoke;
  }
  .textbig {
    display: none;
  }
  .mobileinformation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .otheroptionsmob {
    margin: 1rem;
  }
  .mobilenamediv {
    margin-top: 1rem;
  }
  .informationbig {
    display: none;
  }
  .subbutton {
    max-width: 100px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
  }
  .scrollablediv {
    overflow-y: scroll;
    height: 320px;
  }
  .drawer {
    margin-bottom: 0;
  }
  .basicdetailsdiv {
    height: 500px;
    overflow-y: scroll;
  }
  .doctorcontactlg {
    display: none;
  }
  .doctorcontactsm {
    display: block;
  }
  .twodiv {
    flex-direction: column;
  }
  .namediv {
    width: 100%;
  }
  .namediv {
    width: 100%;
  }
  .reportfoundusername {
    flex-direction: column;
  }
  .basicdetailstab {
    flex-direction: column;
  }
  .left {
    width: 100%;
  }
  .right {
    width: 100%;
  }
  .headingtext {
    line-height: 2rem !important;
    padding: 2rem;
    font-size: 33px;
  }
  .detailsbox {
    font-size: 18px;
  }
  .extrabottomdiv {
    display: flex;
    width: 100px;
    height: 100px;
  }
  .imagebox {
    padding-left: 0px;
  }
  .idcard {
    width: 100%;
    flex-direction: column;
  }
  .drawer {
    height: 9%;
  }
  .guardianinfo {
    display: none;
  }
  .drawer {
    position: fixed;
    bottom: 0px;
    width: 100vw;
  }

  .drawerarrow {
    display: flex;
    font: normal normal normal 14px/1 FontAwesome;
  }
  .boxtext {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .medicaltitle {
    margin-top: 1rem;
    font-size: 12px;
    white-space: nowrap;
  }
}
