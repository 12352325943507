
.formbox{
  width: 100%;
}
.cards-component-wrapper {
  margin-top: 1em;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notes-name-css {
  display: flex;
  flex-direction: column;
  margin-top: -2rem;
}
.cards-component-container {
  position: relative;
  border-radius: 15px;
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2em;
  box-shadow: 0px 0px 5px 1px rgba(179, 179, 179, 0.75);
  background-color: white;
  width: 70%;
}

.cards-component-container-head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
}
.cards-component-container-head img {
  width: 6em;
  height: 6em;
}

.document-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5;
  text-wrap: nowrap;
  position: absolute;
  top: 2.5rem;
  left: 0.5rem;
}

.record-name {
  text-wrap: nowrap;
}

.cards-component-container-head span label {
  font-size: 0.8125rem;
}

.cards-component-container-head span label,
.cards-component-container-head span h5 {
  margin-bottom: 0 !important;
  font-size: initial;
  font-weight: 600;
  text-align: center;
}

.cards-component-kebab-menu {
  position: absolute;
  font-size: 1.25em;
  bottom: 2.4em;
  right: 1rem;
}
.edit-btn {
  width: 1.4em !important;
  height: 1.4em !important;
  margin-right: 4px !important;
  cursor: pointer;
}
.delete-btn {
  width: 1.4em !important;
  height: 1.4em !important;
  margin-left: 4px !important;
  cursor: pointer;
}

.document-icon {
  width: 2em !important;
  height: 2em !important;
  cursor: pointer;
}

.downloaded-img-icon {
  width: 1.75rem;
  height: 1.75rem;
}

.right-side-content {
  position: absolute;
  font-size: 15px;
  bottom: 3em;
  right: 6em;
}
.right-side-vacination-content {
  position: absolute;
  font-size: 15px;
  bottom: 1em;
  right: 5px;
}
.cards-component-main-wrapper-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}

.cards-component-main-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1em;
  /* gap: 5em; */
}

.cards-component-main-wrapper .cards-component-main-content {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.reminder-notes {
  width: 80%;
}

.cards-component-main-content > label,
.cards-component-main-content > h5,
.cards-component-main-content > h6 {
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  gap: 0.3em;
  font-size: 1.1rem !important;
}

.cards-component-main-document-content {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.4em;
}

.cards-component-main-document-content > label,
.cards-component-main-document-content > span {
  margin-bottom: 0px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.3em;
  position: absolute;
  bottom: -2rem;
}

.cards-component-main-content > label,
.cards-component-main-document-content > label {
  color: #61e09a;
}

.cards-component-main-document-content > span a {
  font-size: 1.25rem;
}

.cards-component-delete-dialog {
  margin-bottom: 1em;
  display: flex;
  justify-content: space-around;
}

.cards-component-delete-dialog button {
  width: 40%;
  padding: 0.5em 1em;
  border: none;
  border-radius: 15px;
  font-weight: bold;
  letter-spacing: 1px;
}

.cards-component-delete-dialog button:nth-child(1) {
  background-color: #ffc107;
  color: white;
}

.cards-component-delete-dialog button:nth-child(1):hover {
  background-color: #daa508;
}

.cards-component-delete-dialog button:nth-child(2) {
  background-color: rgb(245, 245, 245);
  color: black;
}

.vaccination-inner-remaining-info-div,
.medication-inner-remaining-info-div,
.medical-inner-remaining-info-div,
.surgery-inner-remaining-info-div,
.measured-inner-remaining-info-div {
  display: flex;
  flex-direction: column;
}

.card__confirm {
  flex: 0.5;
  width: 100%;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #28a745;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  box-shadow: 0px 2px 5px 0px #888888;
}
.card__confirm:hover {
  background-color: #1e7e34;
}
.card__cancel {
  box-shadow: 0px 2px 5px 0px #888888;
  width: 100%;
  flex: 0.5;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #dc3545;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
}

.card__cancel:hover {
  background-color: #bd2130;
}

.inside-info {
  border-radius: 10px;
  box-shadow: 0px 0px 5px grey;
  padding: 15px 2px;
  margin-left: 10px;
  margin-right: 10px;
}

/* Media Queries */

@media only screen and (max-width: 1000px) {
  .delet-med-record {
    display: flex !important;
    width: 650px !important;
  }
}

@media only screen and (max-width: 540px) {
  .delete__dialog h1 {
    text-align: center !important;
  }
}

@media only screen and (max-width: 522px) {
  .docs-no-upload {
    margin-left: 25px !important;
  }

  .medcon-span {
    margin-left: 20px !important;
    background-color: chartreuse !important;
  }

  .med-con-div-1 {
    width: fit-content !important;
    display: block !important;
  }
  .inside-info {
    box-shadow: none;
  }
  .inner-remaining-info-div {
    margin-top: 2rem;
  }
  .vac_row {
    margin-left: -2rem;
  }
}
@media only screen and (max-width: 360px) {
  .major__dialog_btn {
    flex-direction: column !important;
    width: 80% !important;
  }
  .cards-component-container {
    gap: 1em;
  }
  .cards-component-container-head img {
    width: 3em;
    height: 3em;
  }
}

@media only screen and (max-width: 498px) {
  .delete__dialog h1 {
    margin-top: 2px !important;
  }
  .cards-component-container {
    gap: 1em;
    width: 100%;
  }
  .cards-component-container-head img {
    width: 3em;
    height: 3em;
  }
  .cards-component-main-wrapper-flex {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .cards-component-main-content {
    flex-basis: calc(50% - 1em);
    margin: 0.3em;
  }
}

@media only screen and (max-width: 467px) {
  .delet-med-record {
    width: 320px !important;
  }
}

@media only screen and (mx-width: 430px) {
  .major__dialog_btn button {
    margin: auto, 10px !important;
  }
}

@media only screen and (max-width: 400px) {
  .delet-med-record {
    padding: 0px !important;
  }
  .delete-btn-grp {
    flex-direction: column !important;
  }
}

.card__confirm {
  flex: 0.5;
  width: 100%;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #28a745;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  box-shadow: 0px 2px 5px 0px #888888;
}
.card__confirm:hover {
  background-color: #1e7e34;
}
.card__cancel {
  box-shadow: 0px 2px 5px 0px #888888;
  width: 100%;
  flex: 0.5;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: #dc3545;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
}
.card__cancel:hover {
  background-color: #bd2130;
}

/* Media Queries */

@media only screen and (max-width: 1000px) {
  .delet-med-record {
    display: flex !important;
    width: 650px !important;
  }
}

@media only screen and (max-width: 623px) and (min-width: 522px) {
  .top-row img {
    margin-left: -10px !important;
  }
}

@media only screen and (max-width: 522px) {
  .breaking {
    display: none !important;
  }
}

@media only screen and (max-width: 540px) {
  .delete__dialog h1 {
    text-align: center !important;
  }
}

@media only screen and (max-width: 498px) {
  .delete__dialog h1 {
    margin-top: 2px !important;
  }
}

@media only screen and (max-width: 467px) {
  .delet-med-record {
    width: 320px !important;
  }
}
@media only screen and (max-width: 730px) {
  .cards_inside {
    margin-left: 2rem;
  }
}

@media only screen and (mx-width: 430px) {
  .major__dialog_btn button {
    margin: auto, 10px !important;
  }
}

@media only screen and (max-width: 400px) {
  .delet-med-record {
    padding: 0px !important;
  }
  .delete-btn-grp {
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 340px) {
  .major__dialog_btn {
    flex-direction: column !important;
    width: 80% !important;
  }
}

@media only screen and (max-width: 387px) {
  .my-p1 {
    display: inline !important;
  }
}

@media screen and (min-width: 43.75em) {
  /* .cards-component-wrapper {
    padding: 0.5em 7em;
  } */
}

@media screen and (min-width: 48em) {
  /* .cards-component-main-wrapper-flex {
    flex-direction: row;

    justify-content: space-between;
  } */

  .cards-component-main-wrapper {
    flex-direction: column;
    /* gap: 1em; */
  }
}

.tag-number {
  display: flex;
  align-items: flex-start;
  font-size: 15px;
  font-weight: 500;
}
.cardcomp{
  
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
}

.hide {
  display: none;
}
@media screen and (min-width: 992px) {
  .mobile-view {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .laptop-view {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .cards-component-main-wrapper {
    gap: 0em !important;
  }
  .cardcomp{
    gap: 0em !important;

  }
  .cards-component-main-content {
    gap: 0em !important;
  }
}
@media screen and (max-width: 560px) and (min-width: 380px) {
  .middle-content {
    height: 20rem;
  }
}
@media screen and (max-width: 380px) {
  .middle-content {
    height: 25rem;
  }
}
@media screen and (max-width: 320px) {
  .middle-content {
    height: 27rem;
  }
}
