@import url("https://fonts.googleapis.com/css2?family=Prompt&family=Ubuntu:wght@300&display=swap");
@import url('https://fonts.cdn-url-for-mona-sans.css');

/* .header-section {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%; */
/* z-index: 1000; */
/* }  */
.header-change-password-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  margin: 1em;
}

.header-change-password-popup > label {
  margin: 0px !important;
  font-size: 25px;
  font-weight: bold;
}

.header-change-password-popup-input-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.header-change-password-popup-checkbox-input-container {
  display: flex;
}

.header-change-password-popup-input {
  position: relative;
}

.header-change-password-popup-checkbox {
  display: none;
}

.header-change-password-popup-input {
  margin: 0px !important;
  border: 1px solid black;
  border-radius: 5px;
  padding: 1em 2em;
  background-color: white !important;
}

.header-change-password-popup-checkbox-container > label {
  position: absolute;
  padding: 1em;
  right: 1.125em;
}

.header-change-password-popup-btns {
  display: flex;
  gap: 1em;
}

.header-change-password-popup-btns > button {
  border: none;
  border-radius: 5px;
  padding: 1em 1.5em;
}

.header-section {
  position: fixed;
  height: 149px;
  background-color: #ffffff;
  left: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid rgb(167 167 167);
}
.desktop-search-box {
  display: inline-flex;
  position: absolute; /* Keeps it positioned correctly */
  top: 4rem; /* Adjust this for proper alignment */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  height: 60%;
  padding: 10px 25px;
  -webkit-transition: all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
  -webkit-transition-property: background-color;
  transition-property: background-color;
  border: 2px solid transparent !important;
  border-radius: 1rem !important;
  gap: 12px;
  font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  z-index: 999;
}

/* Search input field */
.desktop-search-box .MuiOutlinedInput-root {
  width: 100%;
  border-radius: 1rem;
  border: 2px solid transparent;
  transition: all 0.2s ease-in-out;
  padding-right: 9px !important;
  height: 4rem !important;
}
.report-found-btn {
  background-color: #fcbc1b !important;
  color: black !important;
  font-weight: bold !important;
  border-radius: 7px !important;
  padding: 8px 5px 10px !important;
  text-transform: none !important;
  min-width: 100px !important;
  transition: background-color 0.2s ease-in-out !important;
  line-height: 1 !important;
}
.report-found-btn:focus {
  border: 1px solid black !important;
  outline: none !important;
  box-shadow: none !important;
}
.report-found-btn:hover {
  background-color: #e0a800 !important;
}

/* .header-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  height: 180px;
  z-index: 1000;
  transition: top 0.3s;
} */

.announcement-bar-1-hidden {
  display: none;
}
/* .announcement-bar-1 {
  background: rgb(var(--color-announcement-bar-background-1));
  color: #fff !important;
  text-align: center !important;
  border-bottom: 45px solid #000 !important;
  margin-top: -3.8rem !important;
  width: 105% !important;
  box-sizing: border-box !important;
} */
.announcement-bar-1 {
  position: sticky; /* Keeps it visible at the top */
  top: 0;
  z-index: 1000; /* Ensures it stays above other elements */
  background: #252525f5;
  color: #fff;
  width: 100%; 
}

.announcement-bar__wrapper {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.announcement-bar__message {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  gap: 0.8rem !important;
  text-align: center !important;
  padding: 0.6rem 0.6rem !important;
  min-height: 1rem !important;
}
.announcement-bar__wrapper::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 1px;
  background: #000;
}
.announcement-bar__border {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 1px;
  background: #000;
}
.announcement-bar__text {
  font-weight: 400 !important;
  line-height: 145% !important;
}
.announcement-bar__text p {
  margin: 0 !important;
}
.announcement-bar__text p strong {
  /* margin: 0 !important; */
  font-size: 0.8rem !important;
}
a {
  position: relative;
  text-decoration: none;
  color: #000;
}
/* a :hover {
  color: #000;
} */
.header-nav-ul-li a::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 2px;
  border-radius: 4px;
  background-color: #ffc200;
  bottom: 16px;
  /* left: 0; */
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.header-nav-ul-li a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
  right: 15px;
  color: black;
}

.header-nav-ul-li a:hover {
  color: black;
}
.profile-header-nav-ul-li a::before {
  content: "";
  position: absolute;
  width: 80%;
  height: 2px;
  border-radius: 4px;
  background-color: #ffc200;
  bottom: 3px;
  /* left: 0; */
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.profile-header-nav-ul-li a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
  right: 10%;
  color: black;
}

.profile-header-nav-ul-li a:hover {
  color: black;
}
.announcement-bar__text a {
  position: relative !important;
  color: inherit !important;
  text-underline-offset: 0.4rem !important;
  text-decoration-color: inherit !important;
  z-index: 2 !;
}
.header-activateTag-popup {
  font-family: "prompt";
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}

.header-input__field__dialogs {
  padding: 0.375em 0.75em !important;
  border: 1px solid black;
  border-radius: 15px;
}

.header-verifyOtp-button {
  font-family: "prompt";
  font-size: 14px;
  font-weight: 500;
  background-color: #ffc107;
  letter-spacing: 1px;
  padding: 8px 16px;
  border: none;
  outline: none;
  border-radius: 15px;
  color: white;
  box-shadow: 0px 2px 5px 0px #888888;
}

.header-verifyOtp-button:hover {
  background-color: #ebb105;
}

.header-activateTag-popup {
  font-family: "prompt";
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  margin-bottom: 0;
  text-align: center;
}
.logo-container {
  text-align: center;
  margin: 1rem;
}

.logo {
  max-width: 100%;
  margin-bottom: 20px;
}

.logo-image {
  max-width: 50%;
  height: auto;
  max-height: 60px;
}

.navbar-toggle-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-left: auto;
  margin-top: -55px;
  position: relative;
  z-index: 3;
}

.header-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header-nav-hide {
  /* background: whitesmoke; */
  /* position: absolute; */
  width: 100%;
  /* top: 100%;
  right: -100%; */
  /* transition: 250ms; */
}

/* .header-nav-hide {
  background: #f5f5f5;
  position: absolute;
  top: 100%;
  transition: 0.35s;
} */
.header-nav-ul-li {
  margin-right: 10px;
}
.navbar__options span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: -1rem;
}

.navbar__options {
  color: black;
  font-family: "prompt";
  font-size: 16px !important;
  text-decoration: none !important;
  transition: all 0.2s ease-in-out;
  padding: 1.7rem 1rem !important;
  border: none;
  background: none;
}
.navbar__options:hover {
  color: black;
  text-decoration: none;
  cursor: default;
  outline: none;
}
.got-genie1 {
  height: 55%;
  padding: 10px;
  margin-right: 1rem;
  font-family: "prompt";
  border-radius: 10px;
  width: 165px;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #a78107;
  border-radius: 10px;
  margin-right: 1rem;
}
.header-nav-hide .close-button {
  display: none;
}
/* .got-genie-top {
  color: #00000000;
} */

.got-genie-div-1 {
  display: none;
  margin-left: auto;
  align-items: center;
  overflow: hidden;
  float: left !important;
  outline: none;
}

.got-genie-form {
  margin: 5px;
  padding: 5px;
  margin-right: 4px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  background-color: #24292d;
  border: 1px solid #504d4d;
  border-radius: 10px;
}

.got-genie-form > input {
  width: 70%;
  height: 30px;
  align-self: center;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
}
.genie-form-buttons {
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
}

.genie-form-buttons > button {
  background-color: white;
  padding: 5px 13px;
  margin: 10px 8px;
  margin-top: 12px;
  border-radius: 5px;
}
.genie-form-buttons > button:hover {
  background-color: aliceblue;
  cursor: pointer;
}

.genie-form-buttons > button:hover:disabled {
  cursor: inherit;
}

.genie-form-buttons > button:disabled {
  background-color: #d3d3d3;
}

/* .nav-link {
  text-decoration: none;
  color: #000000;
  font-size: 18px;
} */

@media screen and (max-width: 2000px) {
  .navbar-toggle-btn {
    display: none;
  }
  .header-nav-ul {
    list-style: none;
    /* padding: 0; */
    margin: 1rem 0rem 0rem 9rem;
    display: flex;
    flex-direction: row;
    justify-content: revert-layer;
    /* align-items: center; */
    gap: 1rem
  }
  /* .header-nav-hide {
    width: fit-content;
    display: flex;
    top: 0;
    right: 0;
  } */

  /* .header-nav-ul {
    display: flex;
    align-items: center;
    height: 3.3em;
    max-width: 60em;
  } */
}

@media (max-width: 768px) {
  /* .header-nav-ul.show {
    display: flex; /* Show the options when the show class is applied */
  /* } */
  .header-nav-ul {
    list-style: none;
    display: flex;
    flex-direction: column; /* Change direction to column for smaller screens */
    align-items: baseline;
  }

  .header-nav-ul-li a::before {
    display: none; /* Hide the pseudo-element on smaller screens */
  }

  .header-nav-ul-li a:hover::before {
    transform: scaleX(
      0
    ); /* Ensure the transform is reset for smaller screens */
  }
  .navbar-toggle-btn {
    display: block; /* Show the button */
  }
  .header-section {
    height: 65px;
    position: fixed !important;
    background-color: #ffffff;
    /* top: 40px; Ensure the header stays at the top */
    z-index: 1000;
  }
  /* .announcement-bar-1 {
    width: 100%;
  } */

  .logo-container {
    text-align: left;
    margin: 0.8rem 0rem 1.5rem 1rem;
    width: 9rem;
  }
  .announcement-bar__message {
    padding: 0.8rem 1.6rem !important;
  }
  /* .header-nav {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: hsl(0, 13%, 89%);
    padding-top: 50px;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

  .header-nav .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 24px;
    color: #333;
    outline: none;
    transition: color 0.3s ease;
  }

  .header-nav .close-button:hover {
    color: #ff0000;
  }

  .header-nav {
    width: 60%;
    height: 100vh;
    position: fixed;
    top: 10.8%;
    right: 0;
    background-color: #ffffff;
    padding-top: -6px;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* z-index: 9999; */
  }

  .header-nav ul {
    list-style: none;
    padding: 0;
    margin: 0; /* Resetting margin to zero */
  }

  .header-nav-ul-li,
  .profile-header-nav-ul-li {
    position: relative;
    margin: 0; /* Ensure no extra margin is added to the list items */
    padding: 0.4rem 0; /* Adding some padding for spacing */
    border-bottom: 1px solid #333; /* Adding a bottom border */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  }

  /* Remove the pseudo-element as we are using border-bottom */
  .header-nav-ul-li:after,
  .profile-header-nav-ul-li:after {
    content: none;
  }

  .header-nav-ul-li:last-child,
  .profile-header-nav-ul-li:last-child {
    border-bottom: none; /* Remove bottom border for the last item */
  }

  /* Optional: Style for the anchor tags inside the list items */
  .header-nav-ul-li a,
  .profile-header-nav-ul-li a {
    display: block; /* Ensure the anchor tags take the full width of the list item */
    padding: 0.5rem 1rem; /* Add some padding for spacing */
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Ensure the color is inherited from the parent */
  }

  .navbar__options {
    padding: 0.4rem 0rem !important;
  }
  .header-nav li a {
    padding: 10px; /* Adjust padding as needed */
    text-decoration: none;
    color: #333;
  }

  .header-nav-hide {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.5s ease-out;
  }
  /* .header-nav-ul-li a::before {
    display: none;
  } */
  .desktop-search-box {
    display: none;
  }
  .mobile-search-box {
    display: inline-flex;
    position: absolute; /* Allows movement with top */
    top: 1rem; 
    left: 4rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex-align: center;
    align-items: center;
    width: 80%;
    height: 45%;
    padding: 10px 30px;
    -webkit-transition: all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition: all 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
    -webkit-transition-property: background-color;
    transition-property: background-color;
    border: 2px solid transparent;
    border-radius: 9999px;
    /* outline: 3px solid transparent;
    outline-offset: -3px;
    background-color: #f3f3f6; */
    gap: 12px;
    font-family: "Mona Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    z-index: 999;
  }

  .mobile-search-box .MuiOutlinedInput-root {
    width: 100%;
    border-radius: 1rem;
    /* background-color: #f3f3f6; */
    border: 2px solid transparent;
    transition: all 0.2s ease-in-out;
  }
  .report-found-btn {
    display: inline-flex !important;
    background-color: #fcbc1b !important;
    color: black !important;
    font-weight: bold !important;
    border-radius: 7px !important;
    padding: 6px 3px 7px !important;
    text-transform: none !important;
    min-width: 100px !important;
    transition: background-color 0.2s ease-in-out !important;
    line-height: 1 !important;
}

.report-found-btn:hover {
    background-color: #e0a800 !important;
}

}
/* @media (max-width: 767px) {
  .logo-container,
  .navbar-toggle-btn {
    display: block;
  }
} */
 
@media (min-width: 401px) and (max-width: 768px) {
  .mobile-search-box {
      width: 75%;
      left: 57%;
      transform: translateX(-50%);
      padding: 15px 15px;
      top: 0.8rem;
      font-size: 12px;
  }

  .mobile-search-box .MuiOutlinedInput-root {
      border-radius: 10px;
      font-size: 16px;
  }

  .report-found-btn {
      min-width: 90px !important;
      font-size: 13px !important;
      padding: 5px 4px 5px !important;
      /* background-color: red !important; Test if it's working */
  }

  .header-nav {
    top: 10%;
  }
}

@media (max-width: 400px) {
  .mobile-search-box {
      width: 67%; /* Increase width for smaller screens */
      left: 57%; /* Center align */
      transform: translateX(-50%); /* Adjust positioning */
      padding: 21px 8px; /* Reduce padding */
      top: 0.5rem; /* Adjust top position */
      font-size: 10px; /* Reduce font size */
  }

  .mobile-search-box .MuiOutlinedInput-root {
      border-radius: 8px; /* Reduce border radius */
      font-size: 14px; /* Reduce input font size */
  }

  .report-found-btn {
      min-width: 80px !important; /* Reduce button width */
      font-size: 12px !important; /* Reduce font size */
      padding: 5px 2px !important; /* Adjust padding */
  }
  .header-nav {
    top: 11%;
  }
}

@media (max-width: 360px) {
  .header-nav {
    top: 13%;
  }
}

@media (max-width: 390px) {
  .header-nav {
    top: 12.5%;
  }
}
@media (max-width: 350px) {
  .header-nav {
    top: 10.5%;
  }
}
/* Styles for larger screens */
@media (min-width: 1024px) {
  .mobile-search-box {
    display: none;
  }
}