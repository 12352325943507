/* * {
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: 20px;
  font-family:"prompt, sans-serif"
} */

.apple-auth-btn {
  background-color: black;
  color: white;
  width: 200px;
  transform: none;
  height: "44px";
  font-size: "12px";
  transition: none; /* Disabling the transition */
  overflow: hidden;
  cursor: pointer;
  border: none;
}
.apple-auth-btn:hover {
  background-color: black;
  color: white;
  width: 200px;
  transform: none;

  font-size: "12px";

  height: "44px";
}
.signtext {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.loginbox {
  background-color: whitesmoke;
  padding: 20px;
  border-radius: 8px;

  max-width: 800px;
  width: 90%;
  display: flex;

  align-items: center;
}
.welcometext {
  display: none;
  padding-top: 2rem;
}
.socialicons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Timer-fix {
  margin: -2rem 8rem 1rem;
}
.socialbtn {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  margin: 5px 0;

  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
}
.fb {
  background-color: #3b5998;
  color: white;
}

/* vertical line */
.vl {
  position: absolute;
  left: 50%;

  transform: translate(-50%);
  border: 2px solid #ddd;
  height: 175px;
}

/* text inside the vertical line */
.vl-innertext {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 8px 10px;
}
.socialloginbtn-icon {
  margin: 1rem;
  background-color: white;
  color: black;
  border: 1px solid #dadce0;
}
.socialloginbtn {
  margin: 1rem;
}

.login__background {
  background-image: url("https://storage.googleapis.com/pettag/qr/assets/grey.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 95vh;
}

.login__box {
  background-color: whitesmoke;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6rem 1rem -8rem 1rem;
  opacity: 75%;
}
.otp-css {
  border-radius: "5px";
  width: "100%";
  padding: 0rem 5rem !important;
}
.sm {
  display: none;
}

.login__rightColumn {
  width: 40%;
}
.login_leftcolum {
  width: 50%;
}
.dividerlg {
  width: 10%;
}
.myCustomText {
  text-align: center;
  font-family: "Prompt";
  font-size: 1.1rem;
}
.myCustomSecondText {
  text-align: center;
  font-family: "Prompt";
  font-size: 0.8rem;
  word-break: break-word;
}
@media screen and (max-width: 768px) {
  .loginbox {
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    padding: 0;
  }

  .divider-container {
    width: 100%;
    margin: auto !important;
  }
  .divider-container-signup {
    width: 100%;
    margin: auto !important;
  }
  .login_leftcolum {
    width: 100%;
  }
  .welcometext {
    display: block;
  }
  .lgtext {
    display: none;
  }
  .vl {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .socialloginbtn .MuiButton-label {
    display: none;
  }
  .sm {
    display: block;
  }
  .lg {
    display: none;
  }
  /* .login__background {
    min-height: 100vh;
    margin: 25rem;
  } */
}

.login-input_field_dialogs {
  padding: 0.5em 1em;
  border-radius: 15px;
  border: 1px solid black;
}

.login-otp-buttons {
  border: none;
  background-color: white;
  color: #003a84;
  font-size: 16px;
}
.login-otp-buttons:hover {
  text-decoration: underline;
}
.login-verfiy-btns {
  flex: 0.5 1;
  width: 100%;
  font-family: "prompt";
  font-size: 14px;
  font-weight: 500;
  background-color: #ffc107;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 15px;
  color: white;
  box-shadow: 0px 2px 5px 0px #888888;
}
.login-verfiy-btns:hover {
  background-color: #e0a903;
}
.login-cancel-btns {
  box-shadow: 0px 2px 5px 0px #888888;
  width: 100%;
  flex: 0.5 1;
  font-family: "prompt";
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
  letter-spacing: 1px;
  padding: 7px;
  border: none;
  outline: none;
  border-radius: 15px;
  color: black;
}

.login {
  /* font: 400 14px/20px "prompt, sans-serif"; */
  background-color: whitesmoke;
  width: 100%;
  height: 100vh;
  padding-bottom: 20px !important;
  overflow: hidden;
}

.login__panel {
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  justify-content: center;
  width: 72%;
  margin: auto !important;
  margin-top: 20vh !important;
}
.login__leftColumn {
  flex: 0.33;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}
.login__rightColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0rem 0rem 6rem !important;
}
.text-center {
  text-align: center !important;
}
.login__rightColumn > p {
  font: 400 14px/20px "prompt, sans-serif";
  font-size: 14px;
  letter-spacing: normal;
}
.login__form {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: left !important;
  padding: 20px;
  width: 100%;
}

.divider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: row-reverse;
  margin: 0rem 0rem 6rem 0rem;
}

.divider-container-signup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: row-reverse;
}
.divider {
  flex: 1;
  border: none;
  height: 1px;
  background-color: #8b7c7c;
}

.divider-text {
  background-color: whitesmoke; /* Background color behind the text */
  padding: 0 10px; /* Adjust the padding as needed */
  font-family: "prompt";
  font-size: 1rem;
  color: #777; /* Color of the text */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.login__form > input {
  /* justify-content: center; */
  font: 400 14px/20px "prompt";
  font-size: 16px !important;
  padding: 5%;
  margin: 10px;
  color: #495057;
  background-color: rgb(243, 243, 243);
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 35px !important;
}
::placeholder {
  font-size: 16px !important;
}
#submit__button {
  margin: 10px;
  margin-top: 0px;
  font-family: 16px "prompt, sans-serif";
  background-color: #fcbc1b;
  color: black;
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}
#submit__button:hover {
  cursor: pointer;
  background-color: #cf9b17;
}
.login__forgot {
  /* padding: 12px; */
  display: flex;
  justify-content: flex-end;
}
.login__forgot > button {
  border: none;
  background-color: transparent;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}
.login__forgot > a {
  padding: 12px;
  color: black;
  align-self: center;
}
.login__forgot > a:hover {
  text-decoration: none;
}
.login__forgot :hover {
  background-color: whitesmoke;
}
.login__register {
  font-size: 16px;
  align-self: center;
  text-align: center;
  padding-bottom: 15px;
  white-space: nowrap;
}
.login__register > a {
  padding: 6px;
  color: black;
  align-self: center;
}
.google-login-button {
  font-size: 16px;
  font-weight: bold;
  color: black;
  padding: 5px 5px;
  border-radius: 5px;
  text-transform: none !important;
}
.login__register > a:hover {
  color: black;
  /* text-decoration: none; */
}
.login__google {
  padding-top: 0;
  margin-top: 10px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: left;
  width: 100%;
}
.google__button {
  font: 400 14px/20px "prompt, sans-serif";
  font-size: 16px;
  background-color: white;
  color: black;
  /* height: 40px; */
  /* border: 2px solid black; */
  /* outline: none; */
  border-radius: 5px;
  font-weight: 400;
  width: fit-content;
  /* margin-left: 20px; */
}
.google__button:disabled {
  color: black !important;
  cursor: context-menu !important;
}
.google__button:hover {
  cursor: pointer;
}
.checkbox-css {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.5em;
  /* margin-top: "-11px"; */
}
/* @media screen and (max-width: 1200px) {
  .login__google {
    width: 60% !important;
  }
} */

@media only screen and (max-width: 1140px) {
  .login__form {
    width: 100% !important;
    margin: auto !important;
    padding: 10px !important;
  }

  .google__button {
    width: 100% !important;
  }

  .login__rightColumn {
    padding: 10px !important;
  }

  .google__button {
    width: 120%;
  }
}

@media only screen and (max-width: 1050px) {
  .login__form {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .input_field_dialogs {
    font-size: 14px !important;
    padding-left: 5px !important;
  }
}

@media only screen and (max-width: 950px) {
  .login__form {
    width: 100% !important;
  }
  .google__button {
    width: 125% !important;
  }
}

@media only screen and (max-width: 800px) {
  .login__form {
    width: 100%;
  }
  .google__button {
    width: 150%;
  }
}

@media only screen and (max-width: 648px) {
  .login__rightColumn {
    display: none;
  }
  .dialoginput {
    width: 300px !important;
  }
  /* .verifyOtp-button {
    margin: 10px 40px !important;
  } */
  /* .otp-buttons {
    margin: 10px 40px !important;
  } */
}

@media (max-width: 500px) {
  .dialoginput {
    width: 250px !important;
  }
  /* .verifyOtp-button {
    margin: 10px 20px !important;
  }
  .otp-buttons {
    margin: 10px 20px !important;
  } */
}
@media (max-width: 500px) {
  .dialoginput {
    width: 200px !important;
  }
  .login__panel {
    margin-top: 50px !important;
  }
}

@media only screen and (max-width: 578px) {
  .login__leftColumn {
    display: none;
  }

  .login__form {
    margin-left: 30px !important;
    margin-right: 20px !important;
  }

  .google__button {
    margin-left: 15px !important;
  }
}

@media only screen and (max-width: 480px) {
  .login__panel {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 90% !important;
    padding-left: 0px !important;
  }

  .google__button {
    margin-left: -5px !important;
    width: 130% !important;
  }

  .login__panelWrapper {
    padding: 0px !important;
    margin-top: 10em;
  }

  .login__form {
    width: 100% !important;
    padding-left: 0px !important;
  }
  .login__box {
    margin: 4rem 1rem 0rem 1rem !important;
  }
  .checkbox-css {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 4px;
    gap: 0.5em;
  }
  .login__background {
    min-height: 81vh;
    margin: 0rem;
    width: 100%;
  }
  .Timer-fix {
    margin: auto;
  }
}

@media only screen and (max-width: 340px) {
  .login__form {
    width: 85% !important;
    padding-left: 15px !important;
  }
}

@media only screen and (max-width: 300px) {
  .google__button {
    font-size: 12px !important;
  }
  .verify-mail input {
    width: 150px !important;
    margin-left: 15px !important;
    margin-right: 2px !important;
  }

  .verify-mail button {
    width: 150px !important;
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  /* .change_password_div input .new_email,
  .change_password_div input .new_pwd {
    width: 190px !important;
  } */
}

@media only screen and (max-width: 328px) {
  .verify-mail input {
    width: 200px !important;
    margin: 5px 0px !important;
  }

  .verify-mail button {
    width: 200px !important;
    margin: 5px 0px !important;
  }
}

@media only screen and (max-width: 296px) {
  .change_password_div button {
    width: 180px !important;
  }
  .login__form {
    width: 85% !important;
    padding-left: 15px !important;
  }
}

/* Verify Email Popup Media Queries */

@media only screen and (max-width: 345px) {
  .verify-mail {
    width: 300px !important;
  }
}

@media only screen and (max-width: 305px) {
  .verify-mail {
    width: 270px !important;
  }
}

/* Change Password popup Media Queries */

@media only screen and (max-width: 700px) {
  .inner-change-pwd-div {
    width: 300px !important;
  }

  .inner-change-pwd-div .input-fd {
    width: 250px !important;
  }
}

@media only screen and (max-width: 476px) {
  .inner-change-pwd-div {
    width: 260px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .inner-change-pwd-div .input-fd {
    width: 240px !important;
    margin-left: 5px !important;
    margin-right: 0px !important;
  }

  .inner-change-pwd-div button {
    width: 200px !important;
    margin-left: 5px !important;
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 350px) {
  /* .forget_password_div {
    width: 250px !important;
  }
  .forget_password_div input {
    width: 180px !important;
    margin: 15px auto !important;
  }

  .forget_password_div button {
    width: 180px !important;
    margin: 10px auto !important;
  } */
  .login__form {
    margin-left: 00px !important;
  }
  .login__form > input {
    font-size: 14px !important;
    width: 110%;
  }
  ::placeholder {
    font-size: 14px !important;
  }
  .verify-mail {
    width: fit-content !important;
  }
  .verify-mail input {
    width: 220px !important;
    margin: 5px 0px !important;
  }
  .verify-mail button {
    width: 220px !important;
    margin: 5px 0px !important;
  }
}

@media screen and (max-width: 375px) {
  .login__box {
    margin: 6rem 1rem 0rem 1rem !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 640px) {
  .login__box {
    margin: 0rem 1rem 0rem 1rem !important;
  }
}
/* @media screen and (max-width: 330px) {
  .forget_password_div input {
    width: 180px !important;
    margin: 10px auto !important;
  }

  .forget_password_div button {
    width: 180px !important;
    margin: 5px auto !important;
  }

  .forget_password_div {
    width: 230px !important;
    padding: 5px !important;
  }
} */

@media only screen and (max-width: 900px) {
  .verify-mail input {
    width: 300px !important;
    margin-top: 10px !important;
    margin: 10px auto !important;
  }

  .verify-mail button {
    width: 300px !important;
    margin-top: 5px !important;
    margin: 5px auto !important;
  }

  .verify-mail p,
  .verify-mail h1 {
    margin: auto !important;
    margin-bottom: 10px !important;
    text-align: center !important;
  }
}

@media only screen and (max-width: 600px) {
  .verify-mail {
    padding-top: 30px !important;
  }
  .verify-mail input {
    width: 280px !important;
  }

  .verify-mail button {
    width: 280px !important;
  }
  .myCustomText {
    font-size: 0.9rem;
  }
  .myCustomSecondText {
    font-size: 0.7rem;
    word-wrap: break-word;
    text-align: left !important;
  }
}

@media only screen and (max-width: 415px) {
  .verify-mail input {
    width: 200px !important;
  }
  .verify-mail button {
    width: 200px !important;
  }
  .otp-css {
    padding: 0rem 2rem !important;
  }
}

@media only screen and (max-width: 355px) {
  .verify-mail input {
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 200px !important;
  }
}

@media only screen and (max-width: 347px) {
  .inner-change-pwd-div {
    width: 200px !important;
  }

  .inner-change-pwd-div .input-fd {
    width: 180px !important;
  }

  .inner-change-pwd-div button {
    width: 180px !important;
  }

  .change_password_div {
    padding: 0px;
  }

  /* .verifyOtp-button,
  .otp-buttons {
    width: 80px !important;
    padding: 5px !important;
    margin-left: 55px !important;
  } */

  .inner-change-pwd-div input {
    margin-left: 5px !important;
  }
}
