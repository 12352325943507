li {
  font-size: 18px;
}

ol {
  list-style: none;
  counter-reset: steps;
}

ol>li {
  counter-increment: steps;
}

.popup_li {
  padding: 20px;
  margin-bottom: 0;
}

.MuiDialogContent-root {
  padding: 0 !important;
}

.small_pop_btn {
  border: none !important;
}

.b-div {
  background-color: rgb(237, 236, 236);
}

ol>li::before {
  content: counter(steps);
  margin-right: 0.5rem;
  background: #fcff30;
  /* color: white; */
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  display: inline-grid;
  place-items: center;
  line-height: 1.2em;
  font-size: 15px;
  box-shadow: 0px 0px 5px 1px gray;
}

ol ul li::before {
  background: rgb(255, 3, 3);
  color: white;
}

ul {
  list-style: inside;
}